import React, {useEffect} from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Divider from '@mui/material/Divider';
// import Google from 'assets/images/icons/social-google.svg';
// import Apple from 'assets/images/icons/social-apple.png';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
// import { createTheme } from '@mui/material/styles';
// import validator from 'validator'
// import Logo from 'ui-component/Logo';
// import { Image } from '@material-ui/icons';
// import Atempo from '../assets/phoneLeft.png';
import { useState } from 'react';
import { useContext } from 'react';
// import CustIdContext from 'hooks/customer_idContext';
// import FirebaseIdContext from 'hooks/FirebaseIdContext';
// import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
// import UserContext from 'hooks/userContext';
import { useNavigate } from "react-router-dom";
// import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
// import axios from 'axios';
import visu from '../../assets/images/fondvisu.svg';
// import { makeStyles } from '@mui/styles';
// import logo from '../../assets/images/logoviolet.jpg';
import logo from '../../assets/images/zenego-logo.jpg';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
// import Icon from '@material-ui/core/Icon';
// import AppleIcon from '../../assets/images/apple-brands.svg';
// import GoogleIcon from '../../assets/images/google-brands.svg';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import '../../assets/scss/signUp.scss'
// import { alpha, styled } from '@mui/material/styles';
// import logoAtp from '../../assets/images/logo-atempo.png';
// import AuthLogin from '../pages/authentication/auth-forms/AuthLogin';
import ProductDisplay from 'views/pages/authentication/authentication3/ProductDisplay';

// import paiement from '../../assets/images/paiement.png';
import CouponActive from 'hooks/couponActive';
import { Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import ButtonMailto from './mailto';
import PriceContext from 'hooks/price';



const steps = ['Se connecter','Abonnement' ,'Paiement', 'Confirmation'];

//  const Copyright  = ( props ) => {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Atempo
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const theme = createTheme();

export default function Payment() {
  let navigate = useNavigate();
  if(localStorage.getItem("currentState") === '2'){
  }else navigate('/signin')
  const {couponActive} = useContext(CouponActive)
  let {price} = useContext(PriceContext);
  const [activeStep, setActiveStep] = useState(0);
  const matches = useMediaQuery('(min-width:600px)');
  useEffect(() => {
    setActiveSteping()
  }, [activeStep])


const setActiveSteping = ()=> {
  const testing = window.location;


  if(testing.pathname === "/payment"){
    setActiveStep(2)
  }
}
 

  if(couponActive){
    return (
      <>
      <Grid container component="main" sx={{
        height: '90%',
        backgroundColor: '#412E68',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
           width: 1000,
        },
        ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
          backgroundColor: 'white'
        }
      }}>
        <CssBaseline />
        <Grid
          className="animated fadeInLeft"
          spacing={5}
          component={Paper}
          elevation={3}
          item={true}
          md={5} 
          xs={12} 
          sx={{
            borderTopRightRadius: "10%",
            backgroundColor: 'white',
            justifyContent: 'center',
            borderTopLeftRadius: 0,
            ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
              borderTopRightRadius: 0
            },
            padding: 3,
            paddingLeft: {xs: 1, md: 5},
          }}
        >
          <Typography item={true} sx={{ display: { xs: 'none', md: 'block',  sm: 'block', lg: 'block' },fontSize: 30, marginTop: 5, marginBottom: 5, fontWeight: "bold" }} color="primary">
              <strong>Paiement</strong>
          </Typography>
          <p  style={{marginTop: "-5%"}}>Paiement par carte bancaire {price} € / an </p>
          {/* <p  style={{marginTop: "-5%"}}>Prélevé a l'issue de votre période d'essai de 14 jours</p> */}
          <ProductDisplay></ProductDisplay>
          
        </Grid>
        
        <Grid  item={true} xs={12} sm={8} md={7} 
          sx={{
                display: { xs: 'none', md: 'block' },
                backgroundImage: 'url('+ visu +')',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#412E68',
                backgroundSize: 'cover',
                backgroundPosition: 'right',
        }}
        
        >
          <Grid item={true} md={12} sx={{ paddingTop: '20px' }}>
            <Box sx={{ width: '100%' }} >
            <Stepper  activeStep={2}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
                </Stepper>
              </Box>
          </Grid>
            <Box
              sx={{
                my: 8,
                mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
            >
             <img alt="Logo" style={{ marginLeft: "22%", width: 200}} src={logo} width="200" />
            <Typography style={{ marginLeft: "18%", marginTop: 15,width: 250,textAlign: "center"}} color="white" variant="h3" component="h4">
              Il est temps de prendre soin de vous
            </Typography>;
            
            </Box>
          </Grid>
        </Grid>
        {matches? 
        <div style={{marginTop:'2%'}}>
        <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
             <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
             <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>
        </div>
        <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'40%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
      </div>
    :
    <div style={{marginTop:'2%'}}>
    <div style={{marginTop:'0%', marginLeft:"6%"}}>
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
        {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/mentions-l%C3%A9gales"}>Mentions Légales</a> */}
         {/* <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" /> */}
         {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app"}>Retour site web</a> */}

    </div>
    <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
        {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/cgv"}>Conditons Générales d'Utilisation</a> */}
        {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/vie-privee"}>Vie Privée</a> */}
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
         <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
         {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app"}>Retour site web</a> */}

    </div>
    <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
        {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/cgv"}>Conditons Générales d'Utilisation</a> */}
        {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/vie-privee"}>Vie Privée</a> */}
        {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/mentions-l%C3%A9gales"}>Mentions Légales</a> */}
         {/* <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" /> */}
         <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>

    </div>
    <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'12%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
  </div>
    } 
       
       </>
    );
  }else{
    return (
      <>
      <Grid container component="main" sx={{
        height: '90%',
        backgroundColor: '#412E68',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
           width: 1000,
        },
        ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
          backgroundColor: 'white'
        }
      }}>
        <CssBaseline />
        <Grid
          className="animated fadeInLeft"
          spacing={5}
          component={Paper}
          elevation={3}
          item={true}
          md={5} 
          xs={12} 
          sx={{
            borderTopRightRadius: "10%",
            backgroundColor: 'white',
            justifyContent: 'center',
            borderTopLeftRadius: 0,
            ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
              borderTopRightRadius: 0
            },
            padding: 3,
            paddingLeft: {xs: 1, md: 5},
          }}
        >
          <Typography item={true} sx={{ display: { xs: 'none', md: 'block',  sm: 'block', lg: 'block' },fontSize: 30, marginTop: 2, marginBottom: 5,marginLeft: 12, fontWeight: "bold" }} color="primary">
              <strong>Je paye </strong>
          </Typography>
          <p style={{marginTop: "-5%"}}>Paiement par carte bancaire {price} € / an </p>
          {/* <p  style={{marginTop: "-5%"}}>Prélevé a l'issue de votre période d'essai de 14 jours</p> */}
          <ProductDisplay></ProductDisplay>
         
        </Grid>
        
        <Grid item={true}  xs={12} sm={8} md={7} 
          sx={{
                display: { xs: 'none', md: 'block' },
                backgroundImage: 'url('+ visu +')',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#412E68',
                backgroundSize: 'cover',
                backgroundPosition: 'right',
        }}
        
        >
          <Grid item={true} md={12} sx={{ paddingTop: '20px' }}>
            <Box sx={{ width: '100%' }} >
            <Stepper  activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                  <StepLabel ac>{label}</StepLabel>
                  </Step>
                );
              })}
                </Stepper>
              </Box>
          </Grid>
            <Box
              sx={{
                my: 8,
                mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
            >
             <img alt='logo' style={{ marginLeft: "22%", width: 200}} src={logo} width="200" />
            <Typography style={{ marginLeft: "18%", marginTop: 15,width: 250,textAlign: "center"}} color="white" variant="h3" component="h4">
              Il est temps de prendre soin de vous
            </Typography>;
            
            </Box>
          </Grid>
        </Grid>
        {matches?
    <div style={{marginTop:'2%'}}>
    <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
         <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
         <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>

    </div>
    <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'40%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
  </div>
          :
          <div style={{marginTop:'2%'}}>
          <div style={{marginTop:'0%', marginLeft:"6%"}}>
              <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
              <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
              {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/mentions-l%C3%A9gales"}>Mentions Légales</a> */}
               {/* <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" /> */}
               {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app"}>Retour site web</a> */}
     
          </div>
          <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
              {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/cgv"}>Conditons Générales d'Utilisation</a> */}
              {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/vie-privee"}>Vie Privée</a> */}
              <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
               <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
               {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app"}>Retour site web</a> */}
     
          </div>
          <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
              {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/cgv"}>Conditons Générales d'Utilisation</a> */}
              {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/vie-privee"}>Vie Privée</a> */}
              {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/mentions-l%C3%A9gales"}>Mentions Légales</a> */}
               {/* <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" /> */}
               <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>
     
          </div>
          <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'12%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
        </div>
        }
   </>
    );
  }
 
}