import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    // IconButton,
    // InputAdornment,
    // InputLabel,
    // OutlinedInput,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import { styled } from '@mui/material/styles';

import Google from 'assets/images/icons/social-google.svg';
import Apple from 'assets/images/icons/social-apple.svg';
// import Facebook from 'assets/images/icons/social-facebook.svg';
// import firebase from'../../../../firebase'
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, sendPasswordResetEmail, FacebookAuthProvider } from "firebase/auth";
// import { firebase } from "firebase/app";

// import { auth } from '../../../../firebase'
import CustIdContext from 'hooks/customer_idContext';
import FirebaseIdContext from 'hooks/FirebaseIdContext';
import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
import {get, getDatabase, ref } from 'firebase/database';
import UserContext from 'hooks/userContext'; 
import apiAxios from 'configAxios';
// import usePersistState from 'views/Desktop/Persistance/userPersistState';
// import CancelAtPeriod from 'hooks/cancelAtPeriod';
// import AppleLogin from 'react-apple-login'
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters( {
    locale: 'fr'
} )


// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ props ,...others}, {...rest}) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const {customerId, setCutstomerId} = useContext(CustIdContext)
   const {firebaseId, setFirebaseId} = useContext(FirebaseIdContext)
   const {firebaseEmail, setFirebaseEmail} = useContext(FirebaseEmailContext)
   const {currentUser, setCurrentUser} = useContext(UserContext)
  
    const [checked, setChecked] = useState(false);
    // const [checkedCgv, setCheckedCgv] = useState(false);
    const[disabled, setDisabled] = useState(true)
    const [forgoten, setForgoten] = useState(true)
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)
    // const [openCgv, setOpenCgv]= useState(true)
    // const [isMobile, setIsMobile]=useState(false)
    const [user, setUser]=useState({})
    // const {cancelAtPeriod, setCancelAtPeriod}=useContext(CancelAtPeriod)
    // const [platform, setPlatform]= useState(navigator.userAgentData.platform)
    // const [isIos, setIsIos]=useState()
    // const [test,setTest]=useState()
    const [isAndroid]=useState(false)
    const skRevenueCat = process.env.REACT_APP_RC_WEB_KEY
    // const [aboObjct, setAboObjct]=useState()
    // const [userData, setUserData] = usePersistState('userData', {
    //     token: '',
    //     email: '',
    //   }); 
    const [password, setPassword] = useState('')
    let [email, setEmail] = useState('');
    let navigate = useNavigate();
    const database = getDatabase();
    require('dotenv').config()
    require('dotenv')
    // const SERVER_URL = process.env.REACT_APP_API_URL
    // const pkRevenueCat = process.env.REACT_APP_RC_WEB_KEY

   
 //  REVENUE CAT
//  const data = {
    
//     "app_user_id": firebaseId, // remplacer par l'id firebase de type UID
//     // "user_email": firebaseEmail
// }
const headers = {
    'Accept': 'application/json',
    'X-Platform': 'stripe',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${skRevenueCat}`,
    // 'Authorization': `Bearer ${pkRevenueCat}`,
    'Access-Control-Allow-Origin': "*",
    // 'Access-Control-Allow-Origin' : "https://api.revenuecat.com/v1/subscribers"
}

// let auths = firebase.auth()


function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

    useEffect(() => {
        // Met à jour le titre du document via l’API du navigateur
       if(email.length < 8 || password.length < 8 ){
        setDisabled(true)
       }else setDisabled(false)
      });

      
const appleHandler = async () =>
    {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope( 'name' );
        provider.setCustomParameters({
        // Localize the Apple authentication screen in French.
        locale: 'fr'
        } );
        const auth = getAuth();
        signInWithPopup(auth, provider).then((result) => {
            // The signed-in user info.
            const user = result.user;

            // Apple credential
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            localStorage.setItem(
                'currentState' , "1"
              )
              const userStorage ={
                "currentUser" : firebaseEmail,
                "currentUserId" : firebaseId,
            }
              try{
                localStorage.setItem("currentUser", JSON.stringify(userStorage))
              }catch(err){
                //   console.log(err)
              }
              setUser(result.user);
    
            //    je stock le firebaseEmail et firebaseId
               setFirebaseEmail(result.user.email)
               setFirebaseId(result.user.uid)
    
                // une  fois mes valeurs récupérées j'appelle mes fonctions
                fetchSubIsKilled()
               fetchRevenueCat()
               navigate('/resume')
    })
        .catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.email;
            // The credential that was used.
            // const credential = OAuthProvider.credentialFromError(error);
            console.log(error, 'error')
            // ...
        } );  
    } 


// const auth = getAuth()


    const googleHandler = async () => {

        const auth = getAuth();
        
      signInWithPopup(auth, googleProvider)
        .then(async(result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
          setUser(result.user);

        //    je stock le firebaseEmail et firebaseId
           setFirebaseEmail(result.user.email)
           setFirebaseId(result.user.uid)

            // une  fois mes valeurs récupérées j'appelle mes fonctions
           await fetchSubIsKilled()
           fetchRevenueCat()
           localStorage.setItem(
            'currentState' , "1"
          )
            setUserInLocalstorage()
            navigate('/resume')

        }).catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.email;
            // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        })
    };
    useEffect(() => {
        fetchSubIsKilled()
        fetchRevenueCat()
        setUserInLocalstorage()
        }, [firebaseId, firebaseEmail])

        useEffect(() => {
        }, [currentUser])
        

    // Je récupère l'objet pour voir si il a arrêter son abo
    const fetchSubIsKilled = ()=> {
       get(ref(database, 'users/' +firebaseId)).then(function(snapshot) {
        setCurrentUser(snapshot.val())
        
       });
        // const response =  get(ref(`users/abonnement` + firebaseId))
        
       
        //  setCurrentUser(response.name)
        //  firebaseId = undefined
        
    }
//     const facebookHandler = () => {
//         const auth = getAuth();
//         const provider = new FacebookAuthProvider()
//         auth.languageCode = 'it';
//         provider.setCustomParameters({
//             'display': 'popup'
//           });
         
// signInWithPopup(auth, provider)
//   .then((result) => {
//     // The signed-in user info.
//     const user = result.user;

//     // This gives you a Facebook Access Token. You can use it to access the Facebook API.
//     const credential = FacebookAuthProvider.credentialFromResult(result);
//     const accessToken = credential.accessToken;
//     setUser(result.user);
//   //    je stock le firebaseEmail et firebaseId
//      setFirebaseEmail(result.user.email)
//      setFirebaseId(result.user.uid)

//       // une  fois mes valeurs récupérées j'appelle mes fonctions
//      fetchSubIsKilled()
//      fetchRevenueCat()
//     // ...
//   })
//   .catch((error) => {
//     // Handle Errors here.
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // The email of the user's account used.
//     const email = error.email;
//     // The AuthCredential type that was used.
//     const credential = FacebookAuthProvider.credentialFromError(error);

//     // ...
//   });
//     }
   

    const fetchRevenueCat = () => {
        apiAxios.get('https://api.revenuecat.com/v1/subscribers/' + firebaseId, {headers:headers})      
        .then((response) => {
        const objectSub = response.data.subscriber.subscriptions;
        
            if (!isObjEmpty(objectSub)){
                // alert('TU as déja un abonnement')
                navigate('../account')
            }else{
                // alert('tu n as pas d abonnement')
                navigate('../resume')
            }
        })
        .catch((error) => {
        // console.log(error)
        })
    }

    const setUserInLocalstorage = () => {

        let userCurrent = localStorage.getItem("currentState")

        if(userCurrent > "0"){
            localStorage.setItem(
                'currentState' , "0"
              )
        }else localStorage.setItem(
            'currentState' , "1"
          )

        
          const userStorage ={
            "currentUser" : firebaseEmail,
            "currentUserId" : firebaseId,
        }
        try{
            localStorage.setItem("currentUser", JSON.stringify(userStorage))
          }catch(err){
            //   console.log(err)
          }
         
    }

    const forgot = () => {
        const auth = getAuth();
    sendPasswordResetEmail(auth, email)
  .then(() => {
      setOpen(true)
    // Password reset email sent!
    // ..
  })
  .catch((error) => {
    // const errorCode = error.code;
    // const errorMessage = error.message;
    // ..
  });

    }

    const emailAndPassword = async() => {
        const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password).then(async(userCredential) => {
    // Signed in 
    const user = await userCredential.user;
    // ...

    // const credential = await GoogleAuthProvider.credentialFromResult(userCredential);
  //    je stock le firebaseEmail et firebaseId
     setFirebaseEmail(user.reloadUserInfo.email)
     setFirebaseId(user.reloadUserInfo.localId)

      // une  fois mes valeurs récupérées j'appelle mes fonctions
     await fetchSubIsKilled()
     fetchRevenueCat()
       
     setUserInLocalstorage()
 
        
      
    // navigate('/resume')
  })
  .catch((error) => {
    setError(true)
    // const errorCode = error.code;
    // const errorMessage = error.message;
  });
    }

    // const [showPassword, setShowPassword] = useState(false);
    // const handleClickShowPassword = () => {
    //     setShowPassword(!showPassword);
    // };

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };
    
    
    const AuthButton = styled( Button )`
        color: grey;
        border: 1px solid #F98F83 !important;
        color: #412E68;
        background-color: white !important;
        text-transform: inherit;
    `
    

    return (
        <>
            <Grid container direction="column" justifyContent="center"  spacing={2}>
                {forgoten && <> <Grid item xs={12}>
                    {isAndroid?
                    <div>
                         <AnimateButton>
                        <AuthButton
                            disableElevation
                            fullWidth
                            onClick={googleHandler}
                            size="large"
                            variant="outlined"
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                                <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Se connecter avec Google
                        </AuthButton>
                    </AnimateButton>
                  
                    </div>
                    :
                    <div>
                          <AnimateButton>
                        <AuthButton
                            disableElevation
                            fullWidth
                            onClick={googleHandler}
                            size="large"
                            variant="outlined"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100],
                                marginTop: 2,
                                textTransform: "inherit"
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 },  mt:0.3 }}>
                                <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Se connecter avec Google
                        </AuthButton>
                    </AnimateButton>
                    
                    
                    <AnimateButton>
                        <AuthButton
                            disableElevation
                            fullWidth
                            onClick={appleHandler}
                            size="large"
                            variant="outlined"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100],
                                marginTop: 2,
                                textTransform: "inherit"
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 }, ml:-1, mt:{sm:0.3 }}}>
                                <img src={Apple} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Se connecter avec Apple
                        </AuthButton>
                    </AnimateButton>

                    {/* <AnimateButton>
                        <AuthButton
                            disableElevation
                            fullWidth
                            onClick={facebookHandler}
                            size="large"
                            variant="outlined"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100],
                                marginTop: 2,
                                textTransform: "inherit"
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 }, ml:2, mt:{sm:0.3 }}}>
                                <img src={Facebook} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Se connecter avec Facebook
                        </AuthButton>
                    </AnimateButton> */}
                        </div>}
                  
                </Grid>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <Divider sx={{ flexGrow: 1, borderColor: "#F98F83" }} orientation="horizontal" />

                            <Button
                                variant="outlined"
                                sx={{
                                    cursor: 'unset',
                                    m: 2,
                                    py: 0.5,
                                    px: 7,
                                    borderColor: `#F98F83 !important`,
                                    color: `${ theme.palette.grey[900] }!important`,
                                    fontWeight: 500,
                                    borderRadius: `${ customization.borderRadius }px`
                                }}
                                disableRipple
                                disabled
                            >
                                OU
                            </Button>

                            <Divider sx={{ flexGrow: 1, borderColor: "#F98F83" }} orientation="horizontal" />
                        </Box>
                    </Grid>
                </>}
            </Grid>
            

            <Formik
                initialValues={{
                    email: 'info@codedthemes.com',
                    password: '123456',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid e-mail').max(255).required('E-mail is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                       
                            {/* <InputLabel htmlFor="outlined-adornment-email-login">Email Address / Username</InputLabel> */}
                            {/* <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                variant="standard"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Email Address / Username"
                                
                            /> */}
                            { !forgoten ? 
                            <div>
                                 <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Récupérer son mot de passe</Typography>
                    </Box>
                    
                                </div>
                           : <div> 
                               {error?
                                        <Collapse  in={error}>
                                        <Alert
                                    severity='error'
                                    variant="filled"
                                    // sx={{ mb: 2 }}
                                    >
                                    <AlertTitle>Vous ne renseignez pas le bon e-mail ou votre mot de passe est incorrect</AlertTitle>
                                    </Alert>
                                    </Collapse>
                                    :
                                    <div> </div>

                               }
                              
                               <Grid item xs={12} container alignItems="center" justifyContent="center">
                 
                </Grid>
                               <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{borderRadius: 0, }}>
                                <TextField
                                margin="normal"
                                required
                                type="email"
                                fullWidth
                                id="outlined-adornment-email-login"
                                variant="standard"
                                label="E-mail"
                                // name="email"
                                autoComplete="email"
                                autoFocus
                                // value={values.email}
                                // inputProps={{}}
                                onBlur={handleBlur}
                                // onChange={handleChange}
                                onChange={(event) => {
                                    // setEmail(email);
                                    setEmail(event.target.value)
                                    setFirebaseEmail(event.target.value)
                                    // if(password.length > 0){
                                    //     setDisabled(false)
                                    // }else setDisabled(true)
                                }} 
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                            </FormControl>
                            <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            {/* <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel> */}
                            {/* <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                            /> */}
                            <TextField
                                margin="normal"
                                required
                                // type={showPassword ? 'text' : 'password'}
                                fullWidth
                                // id="outlined-adornment-email-login"
                                variant="standard"
                                type="password"
                                label="Mot de passe"
                                autoComplete="password"
                                autoFocus
                                // value={values.password}
                                // name="password"
                                // inputProps={{}}
                                onBlur={handleBlur}
                                // onChange={handleChange}
                                onChange={(event) => {
                                    // setEmail(email);
                                    setPassword(event.target.value)
                                    if(email.length > 0){
                                        setDisabled(false)
                                    }else setDisabled(true)
                                }} 
                                // endAdornment={
                                //     <InputAdornment positiond="end">
                                //         <IconButton
                                //             aria-label="toggle password visibility"
                                //             onClick={handleClickShowPassword}
                                //             onMouseDown={handleMouseDownPassword}
                                //             edge="end"
                                //             size="large"
                                //         >
                                //             {showPassword ? <Visibility /> : <VisibilityOff />}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        
                          
            <Typography item={true} variant="subtitle1" sx={{ display: { xs: 'none', md: 'block',  sm: 'block', lg: 'block' },marginTop: 5, marginBottom: 5 }}>
            En vous connectant vous acceptez les &nbsp;
            <a variant="subtitle1" style={{color: '#412E68'}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditions Générales d'Utilisations</a>
            &nbsp;et reconnaissez avoir pris connaissance de la politique de 
             {' '}<a variant="subtitle1" style={{color: '#412E68'}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>confidentialité</a>
        </Typography>
  
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid fullWidth item mb={4}>
                            <a style={{width:"100%",arginBottom:"9%",marginLeft:"16%", cursor: 'pointer', marginTop: 12, display: 'block', textDecoration: 'underline', fontSize: 14, color: '#412E68' }} onClick={() => navigate('/signup') }>Pas encore de compte ? Inscrivez-vous</a>
                            </Grid>
                        </Grid>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label="Se souvenir de moi"
                            />
                           
                        </Stack>
                        <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={disabled}
                                    onClick={()=>emailAndPassword()}
                                    fullWidth
                                    style={{textTransform:"initial"}}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Se connecter
                                </Button>
                              
                                {/* <Typography variant="subtitle1">
                                            En vous connectant vous acceptez les &nbsp;
                                            <a variant="subtitle1" style={{color: '#412E68'}} target="_blank" href={"https://www.atempo.app/cgv"}>Conditions Générales d'Utilisations</a>
                                            &nbsp;et reconnaissez avoir pris connaissance de la politique de 
                                            &nbsp;<a variant="subtitle1" style={{color: '#412E68'}} target="_blank" href={"https://www.atempo.app/vie-privee"}>confidentialité</a>
                                        </Typography>
                               */}
                            </AnimateButton>
                            <a style={{marginLeft:"28%", cursor: 'pointer', marginTop: 12, display: 'block', textDecoration: 'underline', fontSize: 14, color: '#412E68' }} onClick={()=> setForgoten(false)} >Mot de passe oublié ?</a>
                           
                           </div> }
                           
                        

                     
                        
                        
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                           
                            <div>
                         
                                {/* <Button onClick={()=> getSubRevenueCat()}>Revenue Cat</Button> */}
                            { !forgoten ? 
                            <div>
                                <TextField
                                margin="normal"
                                disabled={forgoten}
                                required
                                type="email"
                                fullWidth
                                id="outlined-adornment-email-login"
                                variant="standard"
                                label="Votre email de récupération"
                                // name="email"
                                autoComplete="email"
                                autoFocus
                                // value={values.email}
                                // inputProps={{}}
                                onBlur={handleBlur}
                                // onChange={handleChange}
                                onChange={(event) => {
                                    // setEmail(email);
                                    setEmail(event.target.value)
                                }} 
                            /> 
                                 <Button disabled={forgoten} style={{textTransform: "initial"}} variant="contained" onClick={()=>forgot()}>Modifier mon mot de passe</Button>
                                <div>
                                <a onClick={()=> setForgoten(true)} style={{cursor:'pointer',fontWeight: 'normal',marginTop: 10,display: 'block', fontSize: 13}}>Me connecter</a>

                                    </div>
                                <Collapse onMouseOver={()=> setOpen(false)} in={open}>
                                            <Alert
                                severity='success'
                                variant="filled"
                                // sx={{ mb: 2 }}
                                >
                                <AlertTitle>Un mail vous a été envoyé</AlertTitle>
                                </Alert>
                                </Collapse>
                                </div>
                           : <div> </div> }
                          
                                </div>

                            
                            
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default FirebaseLogin;
