import  React, {useEffect}  from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
// import Google from 'assets/images/icons/social-google.svg';
// import Apple from 'assets/images/icons/social-apple.png';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
// import { createTheme } from '@mui/material/styles';
// import validator from 'validator'
// import Logo from 'ui-component/Logo';
// import { Image, SettingsPhone } from '@material-ui/icons';
// import Atempo from '../assets/phoneLeft.png';
import { useState } from 'react';
import { useContext } from 'react';
// import CustIdContext from 'hooks/customer_idContext';
import FirebaseIdContext from 'hooks/FirebaseIdContext';
// import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
import UserContext from 'hooks/userContext';
import { useNavigate  } from "react-router-dom";
// import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
// import axios from 'axios';
import visu from '../../assets/images/fondvisu.svg';
// import { makeStyles } from '@mui/styles';
import logo from '../../assets/images/zenego-logo.jpg';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
// import Icon from '@material-ui/core/Icon';
// import AppleIcon from '../../assets/images/apple-brands.svg';
// import GoogleIcon from '../../assets/images/google-brands.svg';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import '../../assets/scss/signUp.scss'
// import { alpha, styled } from '@mui/material/styles';
import logoAtp from '../../assets/images/logo-atempo.png';
// import AuthLogin from '../pages/authentication/auth-forms/AuthLogin';
import page from '../../assets/images/page.png';
import CouponContext from "hooks/couponContext";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Alert, Card, CardContent, CardMedia, Chip, Snackbar, Switch, useMediaQuery } from '@mui/material';
import CouponActive from 'hooks/couponActive';
// import ProductDisplay from 'views/pages/authentication/authentication3/ProductDisplay';
import apiAxios from 'configAxios';
import { authorization } from 'auth';
import Reabonnement from 'hooks/reabonnement';
import ButtonMailto from './mailto';
import PromoId from 'hooks/promoid';
import CouponIdContext from 'hooks/couponId';
import PriceContext from 'hooks/price';

// const useStyles = makeStyles({
//   grid: {
//   },
//   btn: {
//     backgroundColor: "#F98F83"
//   }
// });

const steps = ['Se connecter','Abonnement' ,'Paiment', 'Confirmation'];


//  const Copyright  = ( props ) => {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Atempo
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const theme = createTheme();



export default function Resume({ navigation }) {
  
  let navigate = useNavigate();
  if(localStorage.getItem("currentState") === '1'){
    
  }else navigate('/signin')
  // const [expiration, setExpiration]=useState('')
  const [stripePlatform, setStripePlatform] = useState()
  const [setStripePlatformIs] = useState(false)
  const [setExpireDate] = useState()
  const [isAboExpire, setIsAboExpire] = useState(true)
    // let [ setEmail] = useState('');
    // let [password, setPassword] = useState('')
  //  let [name, setName] = useState('')
   const {couponActive, setCouponActive} = useContext(CouponActive)
   const {firebaseId, setFirebaseId} = useContext(FirebaseIdContext)
   const {currentUser} = useContext(UserContext)
  const {setPromoId}=useContext(PromoId)
  //  const [emailError, setEmailError] = useState( '' )
   const [check, setCheck] = useState(false)
   const [isPhone, setIsPhone] = useState(false)
  const [checkTwo] = useState( true )
  const [visible, setVisible] = useState(true)
  const { setCouponId}=useContext(CouponIdContext)
  const [activeStep, setActiveStep] = useState(0);
  const { setSeReabonner}= useContext(Reabonnement)
  const [percentReduction, setPercentReduction]=useState()
    let [good, setGood] = useState( false );
  // let [ristourne, setRistourne] = useState()
  // let [prix, setPrix] = useState()
  const {price, setPrice} = useContext(PriceContext);
  let [open, setOpen] = useState( false );
  const matches = useMediaQuery('(min-width:600px)');
  require('dotenv').config()
  let {coupon, setCoupon} = useContext(CouponContext);
  useEffect(() => {

   
}, [currentUser, firebaseId])

  useEffect(() => {
  const usering = localStorage.getItem('currentUser')
const data = JSON.parse(usering);

 setFirebaseId(data.currentUserId)
  }, [])
  
  useEffect(() => {
    setActiveSteping()
  }, [activeStep])
  

const setActiveSteping = ()=> {
  const testing = window.location;

  if(testing.pathname === "/resume"){
    setActiveStep(1)
  }
}
 useEffect(() => {
   
   
 }, [])

 
  const fetchAllPromotionCode = async()=>{
    let isValid ;
    // let coup;
    let u
   
      await apiAxios.get("/all-promotion-code", authorization).then(async(res) => {
        try{
          
          for (let i=0; i<res.data.data.length; i++){
            if (isValid===true)break;
            if (res.data.data[i].code === coupon && res.data.data[i].coupon.valid===true){
               u = res.data.data[i]
              isValid=true
             
              setPromoId(u.id)
              setCouponId(u.coupon.id)

              setPercentReduction(res.data.data[i].coupon.percent_off)
              let reduction = price * res.data.data[i].coupon.percent_off / 100
              setPrice( Number.parseFloat(price - reduction).toFixed(2))
              setCouponActive(true)
              setVisible(false)
              setGood(true)
            }else {
              isValid=false
            }
          }
        }catch(err){
          // console.log(err)
        }
        
        if(isValid === false){
              setOpen(true)
              setPrice(58.99)
              setTimeout( () => {
                setOpen(false)
              }, 4000)
        }
      })
    
   
  }
 
//   const promo = async() =>
//   {
   
  
//     apiAxios.post(`promo`, {coupon: coupon}, authorization).then(res =>
//       {
//           if ( res.data === false )
//           {
//            setOpen(true)
//            setPrice(58.99)
//            setTimeout(() => {
//             setOpen(false)
//           }, 4000); 
//           } else
//           {
//             if(price === 58.99){
//               setCouponActive(true)
//               setVisible(false)
//               setGood(true)
//             }else 
//             console.log(false)
//       } 

  
      
//   } );  
// }
const skRevenueCat = process.env.REACT_APP_RC_WEB_KEY
const headers = {
  'Accept': 'application/json',
  'X-Platform': 'stripe',
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${skRevenueCat}`,
  'Access-Control-Allow-Origin': "*",
}
const apple = "app_store";
const android = "play_store";
apiAxios.get('https://api.revenuecat.com/v1/subscribers/' + firebaseId, {headers:headers})      
.then((response) => {
const objectSub = response.data.subscriber.subscriptions;
setExpireDate(response.data.subscriber.entitlements.Premium.expires_date)
 let timestamp = 1650269360;
 const dateNow = Date.now()/ 1000
 if (timestamp < dateNow){
   setIsAboExpire(true)
   return false
 }else if (timestamp > dateNow){
  setIsAboExpire(false)
 } 

setStripePlatform(JSON.stringify(Object.values(objectSub)[0].store))
  const value = JSON.parse(stripePlatform)

if (value === apple){
  setIsPhone(true)
  setStripePlatformIs(false)
}
  if(value ==="stripe"){ 
    setStripePlatformIs(true)
    
    return false
  }

  if(value ===android){ 
    setStripePlatformIs(false)
    setIsPhone(true)
    return false
  }
})
.catch((error) => {
// console.log(error)
})
  const abonnement = () =>
  {
    const apple = "app_store";
const android = "play_store";

apiAxios.get('https://api.revenuecat.com/v1/subscribers/' + firebaseId, {headers:headers})      
.then((response) => {
const objectSub = response.data.subscriber.subscriptions;
setExpireDate(response.data.subscriber.entitlements.Premium.expires_date)
 let timestamp = 1650269360;
 const dateNow = Date.now()/ 1000
 if (timestamp < dateNow){
   setIsAboExpire(true)
   return false
 }else if (timestamp > dateNow){
  setIsAboExpire(false)
 } 

setStripePlatform(JSON.stringify(Object.values(objectSub)[0].store))
  const value = JSON.parse(stripePlatform)

if (value === apple){
  setIsPhone(true)
  setStripePlatformIs(false)
}
  if(value ==="stripe"){ 
    setStripePlatformIs(true)
    
    return false
  }

  if(value ===android){ 
    setStripePlatformIs(false)
    setIsPhone(true)
    return false
  }
})
.catch((error) => {
// console.log(error)
})
  // const abonnement = () =>
  // {
  //   localStorage.setItem(
  //     'currentState' , "2"
  //   )
  //   if(isAboExpire){
  //     navigate('/payment')
  //   }else if(!isAboExpire && isPhone === true){
  //   }else if(!isAboExpire && isPhone === false){
  //   }
  // }
    localStorage.setItem(
      'currentState' , "2"
    )
    if(isAboExpire){
      navigate('/payment')
    }else if(!isAboExpire && isPhone === true){
    }else if(!isAboExpire && isPhone === false){
    }
  }

  // const prolongationAbonnement = () => {
  //   localStorage.setItem(
  //     'currentState' , "2"
  //   )
  //   navigate('/payment')
  // }

  return (
    <>
    <Grid container component="main" sx={{
      height: '90%',
      backgroundColor: '#412E68',
      ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
         width: 1080,
      },
      ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        backgroundColor: 'white'
      }
    }}>
        <CssBaseline />
        
        
      <Grid
        className="animated fadeInLeft"
        spacing={2}
        component={Paper}
        elevation={3}
        md={5} 
        xs={12} 
        sx={{
          borderTopRightRadius: "10%",
          backgroundColor: 'white',
          justifyContent: 'center',
          borderTopLeftRadius: 0,
          ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
            borderTopRightRadius: 0
          },
          padding: 2,
          paddingLeft: {xs: 1, md: 5},
        }}
      >
        
        <Box sx={{  mx: "auto", width: {xs: 150, md: 150}, display: { xs: 'block', md: 'none',  sm: 'none' } }} >
            <img
            alt='Logo Zenego'
              style={{  width: "50%", marginLeft: '30%', marginBottom: 20 }}
              src={logoAtp} />
        </Box>
          <Typography sx={{ display: { xs: 'none', md: 'block',  sm: 'block', lg: 'block' },fontSize: 30, marginTop: 5, marginBottom: 5, fontWeight: "bold" }} color="primary">
              <strong>Je m'abonne</strong>
        </Typography>
          <Card elevation={1} sx={{ minWidth: 300 }}>
             <CardMedia
              component="img"
              height="194"
              image={page}
              alt="Zen"
            />
            <CardContent>
              <Typography sx={{ mt: 3.5 }} variant="h3" component="div">
               Abonnement annuel 
              </Typography>
              <Typography sx={{ mb: 3.5, mt: 1.5 }} color="text.secondary">
              Devenez Premium pour accéder à l’ensemble des contenus de l’application du Bien-Être ! <br/>
              (Renouvellement automatique tous les 12 mois, annulable à tout moment)
              </Typography>
              <Chip  sx={{ mb: 3.5}} label={`Abonnement à ${price} € / an `} color="warning" />
              {/* <Chip  sx={{ mb: 3.5}} label={`Sans engagement `} color="info" />
              <Chip  sx={{ mb: 3.5}} label={`Essayez gratuitement pendant 14 jours `} color="info" />
              <Chip  sx={{ mb: 3.5}} label={`vous serez prélevés à l'issue de cette période`} color="info" /> */}

              {percentReduction &&
              <Chip  sx={{ mb: 3.5, ml:1}} label={`${percentReduction}% de réduction`} color="warning" />


              }

              {visible?
                <div>
                    
                       {!couponActive&&
                        <form style={{ padding: 5 }}>
                      <TextField onKeyUp={( e ) => setCoupon( e.target.value )} xs={12} variant="standard" required  label="Code promo" helperText="EX: LYON69"  />
                      <Button sx={{ textTransform: 'inherit', marginLeft:"9%", marginTop: "6%" }} xs={12} onClick={() => fetchAllPromotionCode()} color="secondary" variant="contained" >J'applique le code promo</Button>
                    </form>
                        
                      }
                </div>
                :
                <div>
                     {good && <Alert  severity="success">Code promo appliqué ! Total {price} €</Alert>}
                     {good && <Alert severity="info">{price} € pour la première année, puis 58.99€/an.</Alert>}
                </div>
              }
            
          <Snackbar
          open={open}
              autoHideDuration={2}                                          
              message="Ce code promo n’existe pas"
               />                                      
              <Divider style={{ margin: 2 }}></Divider>
              
            </CardContent>
              <Box   sx={{ padding: 2}}  component="div">
              <FormControlLabel control={<Switch onChange={(e) => setCheck(e.target.checked)}  color="secondary" />}
                label="" />
                  <a  variant="subtitle1" style={{color: '#412E68',fontSize:"72%",textDecoration:"none", marginLeft:"-15px", textDecoration: 'underline'}} rel="noreferrer" href='https://www.zenego.fr/cgv' target="_blank">J'accepte les C.G.V</a> 
            </Box>
              {isAboExpire?
              <div>
                   <Box sx={{ padding: 2}}  component="div">
                <Button onClick={() => abonnement()}  disabled={!check || !checkTwo} sx={{ textTransform: 'inherit'}} variant="contained" x size="large">Je m'abonne</Button>
              </Box>
              </div>
              :
              <div>
                {matches?
                <div>
                <Alert  severity='error' style={{padding:"8px"}}>Vous avez déjà un abonnement en cours ! {' '} Vous êtes sur le point de prolonger votre abonnement.</Alert>
                <Button   variant="contained" style={{textTransform:"initial", padding:"6px",marginTop:"3%", marginLeft:"12.5%",marginBottom:"3%"}} onClick={()=>{setSeReabonner(true);navigate('/account')}}>Gérer mon compte</Button>
                </div>
              :
              <div>
              <Alert  severity='error' style={{padding:"8px"}}>Vous avez déjà un abonnement en cours ! {' '} Vous êtes sur le point de prolonger votre abonnement.</Alert>
              <div style={{display:"flex",justifycontent:"center"}}> 
                <Button   variant="contained" style={{textTransform:"initial", padding:"6px",marginTop:"3%", marginLeft:"12.5%",marginBottom:"3%"}} onClick={()=>{setSeReabonner(true);navigate('/account')}}>Gérer mon compte</Button>
                </div>
                </div>
              }
                
                </div>}
           
          </Card>
      
      </Grid>
      
      <Grid   xs={12} sm={8} md={7} 
        sx={{
              display: { xs: 'none', md: 'block' },
              backgroundImage: 'url('+ visu +')',
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#412E68',
              backgroundSize: 'cover',
              backgroundPosition: 'right',
      }}
      
        >
          <Grid md={12} sx={{ paddingTop: '20px' }}>
            <Box sx={{ width: '100%' }} >
            <Stepper  activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
                </Stepper>
              </Box>
          </Grid>
          
          <Box
            sx={{
              my: 8,
            mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
        >
           <img alt='Logo' style={{ marginLeft: "13%", width: 200}} src={logo} width="200" />
        <Typography style={{ marginLeft: "15%", marginTop: 15,width: 250,textAlign: "center"}} color="white" variant="h3" component="h4">
          Il est temps de prendre soin de vous
        </Typography>;
          
          </Box>
        </Grid>
      </Grid>
    
       {matches? 
        <div style={{marginTop:'2%'}}>
        <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
             <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
             <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>
        </div>
        <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'40%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
      </div>
    :
    <div style={{marginTop:'2%'}}>
    <div style={{marginTop:'0%', marginLeft:"6%"}}>
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
  
    </div>
    <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
         <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
         <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
    </div>
    <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
        <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>

    </div>
    <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'12%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
  </div>
    } 
     
      </>
  
  );

}