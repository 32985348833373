import { useSelector } from 'react-redux';
import "@stripe/stripe-js";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
// import Login3 from '../src/views/pages/authentication/authentication3/Login3'
// import CardForm from 'stripeElements/Card';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import CardForm from './stripeElements/Card';
import NavigationScroll from 'layout/NavigationScroll';
import { Route } from 'react-router';
import { useState } from 'react';
import UserContext from 'hooks/userContext';

// ==============================|| APP ||============================== //
import 'helvatica-neue-lt/index.css';

import './App.css';

import CustIdContext from 'hooks/customer_idContext';
import CouponContext from 'hooks/couponContext';
import FirebaseIdContext from 'hooks/FirebaseIdContext';
import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
import CouponActive from 'hooks/couponActive';
import Successing from 'views/Desktop/success';
import Payment from 'views/Desktop/payment';
import ProductDisplay from 'views/pages/authentication/authentication3/ProductDisplay';
import Account from 'views/pages/authentication/auth-forms/Account';
import MyAccount from 'views/Desktop/account';
import InvoiceContext from 'hooks/InvoiceContext';
import SignUpSide from 'views/Desktop/signIn';
import FirebaseLogin from 'views/pages/authentication/auth-forms/AuthLogin';
import Reabonnement from 'hooks/reabonnement';
import IsStripePlatform from 'hooks/isStripePlatform';
import Resume from 'views/Desktop/resume';
import CancelAtPeriod from 'hooks/cancelAtPeriod';
import PromoId from 'hooks/promoid';
import CouponIdContext from 'hooks/couponId';
import PriceContext from 'hooks/price';
import CardErrorContext from 'hooks/CardErrorContext';

const App = () => {
    const customization = useSelector((state) => state.customization);
    const [currentUser, setCurrentUser] = useState('')
    const [customerId, setCutstomerId] = useState('')
    const [coupon, setCoupon] = useState('')
    const [firebaseId, setFirebaseId] = useState()
    const [firebaseEmail, setFirebaseEmail] = useState('')
    const [couponActive, setCouponActive]=useState(false)
    const [link, setLink] = useState('')
    const [seReabonner, setSeReabonner]= useState(false)
    const [stripePlatformIs, setStripePlatformIs] = useState(false)
    const [cancelAtPeriod, setCancelAtPeriod]=useState()
    const [promoId, setPromoId]=useState()
  const [couponId, setCouponId]=useState()
  const [price, setPrice] = useState(58.99);
    const [cardError, setCardError] = useState()
    
    
    return (
        
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
                    <CustIdContext.Provider value={{ customerId, setCutstomerId }}>
                    <CouponContext.Provider value={{ coupon, setCoupon }}>
                    <FirebaseIdContext.Provider value={{ firebaseId, setFirebaseId }}>
                    <FirebaseEmailContext.Provider value={{ firebaseEmail, setFirebaseEmail }}>
                    <CouponActive.Provider value={{couponActive, setCouponActive}}>
                    <InvoiceContext.Provider value={{link, setLink}}>
                    <Reabonnement.Provider value={{seReabonner, setSeReabonner}}>
                      <IsStripePlatform.Provider value={{stripePlatformIs,setStripePlatformIs}}> 
                      <CancelAtPeriod.Provider value={{cancelAtPeriod, setCancelAtPeriod}}>
                          <PromoId.Provider value={{promoId, setPromoId}}>
                              <CouponIdContext.Provider value={{couponId, setCouponId}}>
                                  <PriceContext.Provider value={{price, setPrice}}>
                                    <CardErrorContext.Provider value={{cardError, setCardError}}>
                     {/* <UserProvider> */}
                    <Routes >
                    <Route index element={<SignUpSide/>} />
                    <Route index element={<FirebaseLogin/>} />
                    <Route index element={<Successing/>}/>
                    <Route index element={<Payment/>}/>
                    <Route index element={<Account/>}/>
                    <Route index element={<MyAccount/>}/>
                    <Route index element={<Resume/>}/>
                    <Route path="/payment"  element={<ProductDisplay/>}/>
                    <Route path="/card" element={<CardForm />} />
                    {/* <Route index element={<CardForm />} /> */}
                    </Routes>
                    {/* </UserProvider> */}
                    </CardErrorContext.Provider>
                    </PriceContext.Provider>
                    </CouponIdContext.Provider>
                    </PromoId.Provider>
                    </CancelAtPeriod.Provider> 
                    </IsStripePlatform.Provider>
                    </Reabonnement.Provider>    
                    </InvoiceContext.Provider>
                    </CouponActive.Provider>
                    </FirebaseEmailContext.Provider>
                    </FirebaseIdContext.Provider>
                    </CouponContext.Provider>
                    </CustIdContext.Provider>
                    </UserContext.Provider>
                </NavigationScroll>
                
            </ThemeProvider>
        </StyledEngineProvider>
        
    );
};

export default App;
