import React, {useEffect} from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
// import Google from 'assets/images/icons/social-google.svg';
// import Apple from 'assets/images/icons/social-apple.png';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
// import { createTheme} from '@mui/material/styles';
// import validator from 'validator'
// import Logo from 'ui-component/Logo';
// import { Image } from '@material-ui/icons';
// import Atempo from '../assets/phoneLeft.png';
import { useState } from 'react';
import { useContext } from 'react';
// import CustIdContext from 'hooks/customer_idContext';
import FirebaseIdContext from 'hooks/FirebaseIdContext';
import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
import UserContext from 'hooks/userContext';
// import { useNavigate  } from "react-router-dom";
// import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import axios from 'axios';
import visu from '../../assets/images/fondvisu.svg';
// import { makeStyles } from '@mui/styles';
import logoAtp from '../../assets/images/logo-atempo.png';
import logo from '../../assets/images/zenego-logo.jpg';

// import DeleteIcon from '@mui/icons-material/Delete';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
// import Icon from '@material-ui/core/Icon';
// import AppleIcon from '../../assets/images/apple-brands.svg';
// import GoogleIcon from '../../assets/images/google-brands.svg';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import '../../assets/scss/signUp.scss'
// import { alpha, styled } from '@mui/material/styles';
import Account from '../pages/authentication/auth-forms/Account';
import InvoiceContext from 'hooks/InvoiceContext';
import { Alert, Collapse, Dialog, LinearProgress, useMediaQuery} from '@mui/material';
import { authorization } from 'auth';
import apiAxios from 'configAxios';
import IsStripePlatform from 'hooks/isStripePlatform';
import ButtonMailto from './mailto';
// import { get, getDatabase, ref } from 'firebase/database';
import CancelAtPeriod from 'hooks/cancelAtPeriod';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// const useStyles = makeStyles({
//   grid: {
//   },
//   btn: {
//     backgroundColor: "#F98F83"
//   }
// } );


// const firebaseConfig = {
//     apiKey: "AIzaSyBk-zDoAic8F4an-NzmkW34SRdgKBySkiI",
//     authDomain: "atempo-3da4a.firebaseapp.com",
//     databaseURL: "https://atempo-3da4a-default-rtdb.europe-west1.firebasedatabase.app",
//     projectId: "atempo-3da4a",
//     storageBucket: "atempo-3da4a.appspot.com",
//     messagingSenderId: "518853957556",
//     appId: "1:518853957556:web:8ebf394eb406bde0a544e1",
//     measurementId: "G-KS2TP69MHQ"
// };

//  const Copyright  = ( props ) => {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Atempo
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const theme = createTheme();



export default function MyAccount() {
    // let [email, setEmail] = useState('');
    // let [password, setPassword] = useState('')
  //  let [name, setName] = useState('')
  let [forgotten, setForgotten] = useState(false) 
  const {firebaseId} = useContext(FirebaseIdContext)
   const {currentUser} = useContext(UserContext)
   const {firebaseEmail} = useContext(FirebaseEmailContext)
    // const [ setEmailError] = useState( '' )
    const [invoice, setInvoice] = useState('')
    const [expiration, setExpiration]=useState('')
    const [typeofExpiration, setTypeofExpiration] = useState()
    const [stripePlatform, setStripePlatform] = useState()
    const {stripePlatformIs, setStripePlatformIs} = useContext(IsStripePlatform)
    const {link,setLink} = useContext(InvoiceContext)
    const [expireDate, setExpireDate] = useState()
    const [ setIsAboExpire] = useState(false)
    // const [user, setUser]= useState()
    const [open, setOpen] = useState(false);
    const [openn, setOpenn] = useState(false);
    const {cancelAtPeriod, setCancelAtPeriod}=useContext(CancelAtPeriod)
    // const [test, setTest] = useState('dPxcAz27JqgEwZCFEfcU3nWVwpR2')
    // const [test, setTest] = useState('rR2KOF9YaFWhLJuqEBnWvHh5ktq1')
    // const [test, setTest] = useState('tvo1yT1UOubNQwSQIvOzZ6txRmI2')//android
    // const [usering, setUsering] = useState(localStorage.getItem('currentUser'))
    // const [test, setTest] = useState('JBMK5sjRZGa9DexxXhVask0BhNt2')//expiration
    // const [validpopup, setValidpopup]= useState()
    const matches = useMediaQuery('(min-width:600px)');
    
    require('dotenv').config()
    // const SERVER_URL = process.env.REACT_APP_API_URL
  // let navigate = useNavigate();

  const skRevenueCat = process.env.REACT_APP_RC_WEB_KEY
  const headers = {
    'Accept': 'application/json',
    'X-Platform': 'stripe',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${skRevenueCat}`,
    // 'Authorization': `Bearer ${pkRevenueCat}`,
    'Access-Control-Allow-Origin': "*",
    // 'Access-Control-Allow-Origin' : "https://api.revenuecat.com/v1/subscribers"
}

//  const usering = localStorage.getItem('currentUser')
//  console.log('usering: ', JSON.parse(usering))
//  const data = JSON.parse(usering);
 
//   setFirebaseEmail(data.currentUser)
//   setFirebaseId(data.currentUserId)
const apple = "app_store";
const android = "play_store";


useEffect(() => {
  // console.log(firebaseId, "blibliblib")

  // console.log(currentUser, 'YYYY')
  }, [firebaseId, firebaseEmail])

  useEffect(() => {
    // console.log(firebaseId, "blibliblib")
    
    }, [firebaseId, currentUser])
 
            //  const data = JSON.parse(usering);
            //  console.log(data.currentUserId, 'hsdkjfhjsdhfdfk')
            //   setFirebaseEmail(data.currentUser)
            //   setFirebaseId(data.currentUserId)
            const fetchSubExpireDate = () => {
              axios.get('https://api.revenuecat.com/v1/subscribers/' + firebaseId, {headers:headers})      
.then((response) => {
// console.log(response.data, "AAAAAAAAAA")
const objectSub = response.data.subscriber.subscriptions;
setExpireDate(response.data.subscriber.entitlements.Premium.expires_date)
// console.log(expireDate)
let timestamp = Date.parse(expireDate )/ 1000;
//  let timestamp = 1650269360;
// console.log(timestamp)
const dateNow = (Date.now()/ 1000)
// console.log(JSON.parse(dateNow), "(((((((")
// setCurrentUser(response.data.subscriber.original_app_user_id)
if (timestamp < dateNow){
//  console.log('abonnement expiré')
 setIsAboExpire(true)
 return 
}else if (timestamp > dateNow){
setIsAboExpire(false)
// console.log('abonnement valide')
} 
// let timestamp = expireDate.slice(0 , 10);
// timestamp =  timestamp.split("-")
// const timestampDeux =  new Date(timestamp[2], timestamp[1] , timestamp[0])
// console.log(timestampDeux.getTime() + '    Timestamp')
// console.log(Object.values(objectSub) + "jkqhfhqfq"); 
// console.log(JSON.stringify(Object.values(objectSub)[0].store))
setStripePlatform(JSON.stringify(Object.values(objectSub)[0].store))
const value = JSON.parse(stripePlatform)
// stripePlatform = 'app_store'
// console.log(stripePlatform + "BLIBLI")
// console.log(stripePlatform.localeCompare('app_store'));
// console.log(value === "app_store")
if (value === apple){
setStripePlatformIs(false)
// console.log('APPEULE STORE')
}
if(value ==="stripe"){ 
  // console.log(false)
  setStripePlatformIs(true)
  return 
}

if(value ===android){ 
  // console.log(false)
  setStripePlatformIs(false)
  return 
}
})
.catch((error) => {
// console.log(error)
})
            }
  


  
// console.log(cancelAtPeriod, "account cancel_at_period")
  useEffect(() => {
    setTimeout(() => {
      // console.log('This will run after 1 second!')
      setOpen(false)
    }, 3000);
  }, [open])
  

 
 

 
  
// const axiosInstance = axios.create({
//   baseURL: SERVER_URL
// })
  //  const validateEmail = (e) => {
  //   var email = e.target.value
  
  //   if (validator.isEmail(email)) {
  //     setEmailError(true)
  //     return 
  //   } else {
  //     setEmailError(false)
  //     return 
  //   }
  // }
// console.log(expiration)


// useEffect(() => {
//   getInvoices()
//   console.log(currentUser, "#########")
//   console.log(expiration)
// }, [expireDate, expiration])

// const fetchSubIsKilled = ()=> {
//   const database = getDatabase()
//   get(ref(database, 'users/' +firebaseId)).then(function(snapshot) {
//    console.log(snapshot.val(), "eeeeeeeeee")
//    setCurrentUser(snapshot.val())
   
//   });
//    // const response =  get(ref(`users/abonnement` + firebaseId))
   
  
//    //  setCurrentUser(response.name)
//    //  firebaseId = undefined
//     console.log(firebaseId, "ggggg")
//    //  console.log(response, 'response')
   
// }

useEffect(() => {
  getInvoices()
  getInvoice()

}, [invoice,link])
console.log(expiration)

useEffect(() => {
  if(expiration === "NaN/NaN/NaN") {
    setTypeofExpiration(true)
    }else {
      setTypeofExpiration(false)
    }
}, [expiration])



  // if(expiration){
  //   setTypeofExpiration(false)
  // }
const getInvoices = async()=>{
  if (typeof(currentUser)==="string"){
    apiAxios.post(`current/subscription`, {sub_id: currentUser}, authorization).then(async(res)=> {
      try{
        setInvoice(res.data.latest_invoice);
        // console.log(res.data, "AAAAAAAAAA")
        // console.log(invoice, 'INVOICE')
        setCancelAtPeriod(res.data.cancel_at_period_end)
        // console.log(cancelAtPeriod, "????????")
        // console.log(res.data.current_period_end)
        // console.log(currentUser, 'CURRENT USER SUB ID')
        const timestamp= res.data.current_period_end
       let date=new Date(timestamp * 1000)
       const date2 = date.getDate();
      //  console.log("trucmuch")
      // console.log(date2.toString(), ")))))))))))")
        const date3= date.getMonth()+1
      // console.log("trucmuch")
      // console.log(date2.toString(), ")))))))))))")
      let day = date2.toString()
      let month  = date3.toString()
      // console.log(date, "date1")
      // if(typeof date === "Invalid Date"){
      //   console.log("faileeeed")
      // }
      // // console.log(day.length, "PPPPPPP")
      // if(day.length < 2 && month.length > 1){
      //   setExpiration(" 0"+date.getDate()+
      //   "/"+(date.getMonth()+1)+
      //   "/"+date.getFullYear());
      // }else if (day.length > 1 && month.length < 2){
      //   setExpiration(date.getDate()+
      //   "/0"+(date.getMonth()+1)+
      //   "/"+date.getFullYear());
      // }else{
      //   setExpiration(date.getDate()+
      //   "/"+(date.getMonth()+1)+
      //   "/"+date.getFullYear());
      // }
    
      // if(day.length < 2 && month.length < 2){
      //   setExpiration(" 0"+date.getDate()+
      //   "/0"+(date.getMonth()+1)+
      //   "/"+date.getFullYear());
      // }
      //  setExpiration(date.get)
      
       
      await getInvoice()
       return
      }catch(err){
        // console.log(err)
      }
      return
  });
  }else{
    apiAxios.post(`current/subscription`, {sub_id: currentUser.abonnement.sub_id}, authorization).then(async(res)=> {
      try{
        // console.log(res.data.current_period_end)
        // console.log(currentUser, 'CURRENT USER SUB ID')
        const timestamp= res.data.current_period_end
       let date=new Date(timestamp * 1000)
      //  const date2 = Math.floor(new Date().getTime() / 1000);
      const date2 = date.getDate();
        const date3= date.getMonth()+1
      // console.log("trucmuch")
      // console.log(date2.toString(), ")))))))))))")
      let day = date2.toString()
      let month  = date3.toString()
      // console.log(month.length, "PPPPPPP")
        // console.log(date, "date 2")
        // if(date === "Invalid Date"){
        //   console.log("faileeeed")
        // }
          if(day.length < 2 && month.length > 1){
            setExpiration(" 0"+date.getDate()+
            "/"+(date.getMonth()+1)+
            "/"+date.getFullYear());
          }else if (day.length > 1 && month.length < 2){
            setExpiration(date.getDate()+
            "/0"+(date.getMonth()+1)+
            "/"+date.getFullYear());
          }else{
            setExpiration(date.getDate()+
            "/"+(date.getMonth()+1)+
            "/"+date.getFullYear());
          }
        

      // if(day.length < 2 && month.length < 2){
      //   setExpiration(" 0"+date.getDate()+
      //   "/0"+(date.getMonth()+1)+
      //   "/"+date.getFullYear());
      // }

     
      //  setExpiration(date.get)
       setInvoice(res.data.latest_invoice);
      //  console.log(res.data, "AAAAAAAAAA")
      //  console.log(invoice, 'INVOICE')
       setCancelAtPeriod(res.data.cancel_at_period_end)
      //  console.log(cancelAtPeriod, "????????")
       
      await getInvoice()
       return
      }catch(err){
        // console.log(err)
      }
      return
  });
  }

}



 
    


//   axios.post('http://localhost:4000/current/subscription', {sub_id: currentUser}).then((res)=> {
//    setInvoice(res.data.latest_invoice);
//    getInvoice()
// });
   const getInvoice = async()=> {
     await apiAxios.post( `get-invoice`, { intent_id: invoice }, authorization ).then( ( res ) =>
     {
      //  console.log("res.data.invoice_pdf")
      //  console.log(res.data)
    // console.log(res.data.invoice_pdf)
     setLink(res.data.invoice_pdf)
    
     return
     // return(
     //   <button href={{link}}></button>
     // )
   })
   return
  }
  const loadInvoice = () => {
    // console.log(link, 'LINK')
   
    
   
    }
    function isObjEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
  

    useEffect(() => {
      // console.log(currentUser, 'RRRRRRRRRRR')
      // console.log(firebaseId, "PPPPPPPP")
      
      if (!isObjEmpty(currentUser)&& firebaseId.length > 0){
        // alert('TU as déja un abonnement')
        getInvoices()
        loadInvoice()
        fetchSubExpireDate()
    }
      // if(currentUser ){
        
      // }console.log("nope")
     
    }, [currentUser, link])
    
    // console.log(stripePlatformIs, "!!!!!!")

    const renouvellement = () =>{
      
     
        if (typeof(currentUser)==="string"){
          try{
            apiAxios.post('update/subscription',{'sub_id':currentUser, 'cancel_at_period': false}, authorization).then((res)=>{
                // console.log(res.data)
                // console.log('GOOOOOOOOOOOOOOOOOOOOOOOOOOOD')
                setCancelAtPeriod(false)
                setOpen(true)
                setOpenn(false)
              }).catch((err)=>{
                // console.log(err)
              })
            // const res = await apiAxios.post(`delete/subscription`, {"subscription_id": currentUser}, authorization)
            // setOpen( true )
           
        }catch(err){
            // console.log(err)
        }
        }else{
          try{
            apiAxios.post('update/subscription',{'sub_id':currentUser.abonnement.sub_id, 'cancel_at_period': false}, authorization).then((res)=>{
                // console.log(res.data)
                // console.log('GOOOOOOOOOOOOOOOOOOOOOOOOOOOD')
                setCancelAtPeriod(false)
                setOpen(true)
                setOpenn(false)
              }).catch((err)=>{
                // console.log(err)
              })
            // const res = await apiAxios.post(`delete/subscription`, {"subscription_id": currentUser}, authorization)
            // setOpen( true )
           
        }catch(err){
            // console.log(err)
        }
        }
        
  }


  // const handleSubmit = (event) => {
  //   event.preventDefault();
    // const data = new FormData(event.currentTarget);
  //   // eslint-disable-next-line no-console
  //   // console.log({
  //   //   email: data.get('email'),
  //   //   password: data.get('password'),
  //   // });
  // };
  
  // const classes = useStyles();
  
  const handleClose = () => {
    setOpenn(false);
  };

  
  return (
    
    <>
    {matches?
     <div style={{display:"flex", justifyContent:"flex-end",alignItems:'center' ,width:"21%", marginLeft:"103%", height: "3%"}}>
     <p style={{fontSize:"15px"}}><a style={{textDecoration:"none", color:"#412E68"}} target="_blank" rel="noreferrer" href='https://zenego.fr/'>Retour site web</a></p>
       <Button style={{width:"5%"}} target="_blank" href='https://zenego.fr/'>
        
     <img style={{width:"100%"}} alt="logo Zenego" src={logoAtp}/>
 
       </Button>
 
     </div>
      :
      <div style={{display:"flex", justifyContent:"flex-end",alignItems:'center' ,width:"48%", marginLeft:"67%", height: "5%"}}>
     <p style={{fontSize:"13px"}}><a style={{textDecoration:"none", color:"#412E68"}} rel="noreferrer" target="_blank" href='https://zenego.fr/'>Retour site web</a></p>
       <Button style={{width:"5%"}} target="_blank" href='https://zenego.fr/'>
        
     {/* <img style={{width:"50%"}} src={logoAtp}/> */}
 
       </Button>
 
     </div>
    }
   
    <Grid container component="main" sx={{
        height: '140%',
      
      backgroundColor: '#412E68',
      ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
         width: 880,
      },
      ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        backgroundColor: 'white',
        height:"100%"
        },
      
    }}>
      
      <CssBaseline />
      <Grid
        className="animated fadeInLeft"
        spacing={5}
        component={Paper}
        elevation={3}
        item={true}
        md={6} 
        xs={12} 
        sx={{
          borderTopRightRadius: "10%",
          backgroundColor: 'white',
          justifyContent: 'center',
          borderTopLeftRadius: 0,
          ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
            borderTopRightRadius: 0
          },
          padding: 4,
          paddingLeft: { xs: 1, md: 10 },
          marginleft:"-20%",
        }}
      >
        
        <Dialog
        open={openn}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Votre abonnement"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous confirmez reprendre le renouvellement automatique de votre abonnement ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Non</Button>
          <Button onClick={()=> {renouvellement()}} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
        <Box item={true} sx={{  mx: "auto", width: {xs: 150, md: 150}, display: { xs: 'block', md: 'none',  sm: 'none' } }} >
            <img
            alt='logo Zenego'
              style={{  width: "50%", marginLeft: '30%', marginBottom: 20 }}
              src={logoAtp} />
        </Box>
          <Typography item={true} sx={{ display: { xs: 'none', md: 'block',  sm: 'block', lg: 'block' },fontSize: 30, marginTop: 5, marginBottom: 5, fontWeight: "bold" }} color="primary">
              <strong>Mon compte</strong>
        </Typography>
        <Grid style={{marginTop: "10%"}}>
        <p>Votre e-mail : { firebaseEmail }</p>
        { stripePlatformIs?
        <div>
           <p>Votre abonnement : Annuel</p>
           {expiration?
           <div>
             {cancelAtPeriod?
              <div>
                 <p>Date d'expiration : {expiration}</p>
                </div>
                :
                <div>
                   <p>Date de renouvellement : {expiration}</p>
                </div>
              }
              </div>
              :
              <div>

                </div>
           }
          
       
        </div>
          : <div>
             <p>Veuillez gérer vos abonnements sur votre téléphone</p>
          </div>
        }
         
            { stripePlatformIs? 
          <div>
             <Button variant="contained"
          fullWidth
          size="large"
          onClick={()=> {loadInvoice(); setForgotten(true);setTimeout( () =>
            {
             setForgotten(false)
              window.location = ( link )
              function download(dataurl) {
                const link = document.createElement("a");
                link.href = dataurl;
                link.click();
              }
        
              download(link, "invoice.pdf"); 
              
            }, 4000)}}
          // onClick={getInvoices}
     
          sx={{ textTransform: 'inherit', textDecoration: 'none' }}
          style={{ color: "white", textDecoration: 'none', textTransform: 'inherit', background: "#F98F83" }}>
          Ma facture</Button>
        {forgotten && <LinearProgress color="secondary" />}
          </div>:
          <div>
         
        </div>
          }
           
          
            
            
            {cancelAtPeriod  && <Button
              style={{ marginTop: 20 }}
              variant="contained"
              color="primary"
              sx={{ textTransform: 'inherit', textDecoration: 'none' }}
              fullWidth
              size="large"
              onClick={() => setOpenn(true)}>Je renouvelle mon abonnement</Button>}
            
         </Grid>
         <Collapse style={{height: "3%"}} in={open} >
                <Alert style={{height: "5%"}}
      // sx={{ mb: 19 }}
    >
      Votre demande a bien été prise en compte, votre abonnement sera renouvellé
    </Alert>
    </Collapse>
          
          <Divider></Divider>
         <Grid  style={{marginTop: "0%", height: "30%", display: "flex", alignItems: "flex-end"}}>
         <Account />
         </Grid>
         
         
        </Grid>
      
      <Grid item={true}  xs={12} sm={8} md={6} 
        sx={{
              display: { xs: 'none', md: 'block' },
              backgroundImage: 'url('+ visu +')',
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#412E68',
              backgroundSize: 'cover',
              backgroundPosition: 'right',
      }}
      
      >
          <Box
            sx={{
              my: 8,
            mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
        >
           <img alt='logo' style={{ marginLeft: "22%", width: 200}} src={logo} width="200" />
        <Typography style={{ marginLeft: "18%", marginTop: 15,width: 250,textAlign: "center"}} color="white" variant="h3" component="h4">
          Il est temps de prendre soin de vous
          </Typography>
          
          </Box>
        </Grid>
      </Grid>
      {matches?
       <div style={{marginTop:'2%'}}>
       <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://zenego.fr/vie-privee"}>Vie Privée</a>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
            <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr"/>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://zenego.fr"}>Retour site web</a>
       </div>
       <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'40%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
     </div>
        :
        <div style={{marginTop:'2%'}}>
        <div style={{marginTop:'0%', marginLeft:"6%"}}>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://zenego.fr/vie-privee"}>Vie Privée</a>
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a> */}
             {/* <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" /> */}
             {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://zenego.fr"}>Retour site web</a> */}
   
        </div>
        <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://zenego.fr/cgv"}>Conditons Générales d'Utilisation</a> */}
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://zenego.fr/vie-privee"}>Vie Privée</a> */}
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
             <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
             {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://zenego.fr"}>Retour site web</a> */}
   
        </div>
        <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://zenego.fr/cgv"}>Conditons Générales d'Utilisation</a> */}
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://zenego.fr/vie-privee"}>Vie Privée</a> */}
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a> */}
             {/* <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" /> */}
             <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://zenego.fr"}>Retour site web</a>
   
        </div>
        <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'12%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
      </div>
      }
      </>
  
  );
}