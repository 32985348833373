import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
// import axios from 'axios';
// import { useState } from 'react';
require('dotenv').config()
const stripeKey= process.env.REACT_APP_STRIPE_PUBLIC_KEY

// ==============================|| REACT DOM RENDER  ||============================== //
const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
      }
    ]
  };
    // const [publishableKey, setPublishableKey] = useState('');
    //  await axios.get('http://localhost:4242/config').then((res)=>setPublishableKey(res.data) )
    //  fetch('/config').then((r) => r.json());
    const stripePromise2 = loadStripe(stripeKey)
    ReactDOM.render(
        <Provider store={store}>
            <Elements stripe={stripePromise2} options={ELEMENTS_OPTIONS}>
            <BrowserRouter>
            
        <App />
      
            </BrowserRouter>
            </Elements>
        </Provider>,
        document.getElementById('root')
    );



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
