import { Alert, Card, CardContent, CardMedia, Snackbar } from "@mui/material";
// import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import axios from "axios";
import CouponContext from "hooks/couponContext";
// import CustIdContext from "hooks/customer_idContext";
// import FirebaseEmailContext from "hooks/FirebaseEmailContext";
// import UserContext from "hooks/userContext";
import React, { useState, useContext } from "react";
import CardForm from "stripeElements/Card";
import paiement from '../../../../assets/images/paiement.jpg';
// import InjectedCardForm from 'stripeElements/Card';
const ProductDisplay = () => {
  // let stripePromise2 = loadStripe(process.env.STRIPE_PUBLIC_KEY);
  let [open] = useState( false );
  let [good] = useState( false );
  // let [price] = useState(47.19);
  // let [ristourne, setRistourne] = useState()
  let [prix] = useState()
  // let [amount, setAmount] = useState(price)
  // const {currentUser} = useContext(UserContext);
  // const {customerId, setCutstomerId} = useContext(CustIdContext)
  // const { firebaseEmail, setFirebaseEmail } = useContext( FirebaseEmailContext )
  const {couponActive} = useContext(CouponContext)
  // const {couponVisible, setCouponVisible} = useState(true)
  require('dotenv').config()
    // const SERVER_URL = process.env.REACT_APP_API_URL

  
  // const createCheckoutSession = () => {
  //    axios.post(`${SERVER_URL}create-checkout-session`, {customer_id: currentUser}).then(res => {
  // })
  // }

  if(couponActive === true){
    return(
      <section>
         <Card elevation={1} sx={{ minWidth: 300 }}>
               <CardMedia
                component="img"
                height="194"
                image={paiement}
                alt="Zen"
          />
         
          {/* {couponActive? ' 47.19 / an' : ' 58.99e / an'} </p> */}
          {/* <p>itemId: {JSON.stringify(coupon)}</p> */}
          
          <CardContent>
             
            
              <CardForm/>
             
                
                
            
            </CardContent>
          </Card>
          
       
          <Snackbar
          open={open}
              autoHideDuration={6000}                                            
              message="Aucun code promo ne correspond                                            "
               />                                       
           {good && <Alert severity="success">Code promo appliqué ! Total {prix} euros</Alert>}
                                            
  
      
    </section>
  )
  }else{
    return(
      <section>
         <Card elevation={1} sx={{ minWidth: 300 }}>
               <CardMedia
                component="img"
                height="194"
                image={paiement}
                alt="Zen"
          />

          <CardContent>
             
            
              <CardForm/>
             
                
                
            
            </CardContent>
          </Card>
          
       
          <Snackbar
          open={open}
              autoHideDuration={6000}                                            
              message="Aucun code promo ne correspond                                            "
               />                                       
           {good && <Alert severity="success">Code promo appliqué ! Total {prix} euros</Alert>}
                                            
  
      
    </section>
  )
  }
  
 };
export default ProductDisplay