import axios from 'axios'

const base = {
  // url: "http://localhost:5001/atempo-3da4a/us-central1/api"
  // url: 'https://us-central1-atempo-3da4a.cloudfunctions.net/api'
  url: 'https://api.application-bienetre.com',
  // url: 'https://atempo-app.com',
  // url: 'http://localhost:4000'
  // url: 'http://localhost:8000'
  // url: 'https://api.revenuecat.com/v1/'
}
// faire une config ocalhost
// const skRevenueCat = process.env.REACT_APP_RC_WEB_KEY

const apiAxios = axios.create({
  baseURL: base.url,
//   headers : {
//     'Accept': 'application/json',
//     'X-Platform': 'stripe',
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer strp_TnvvFRazRTZHGhXLfoJaLKHxXMs`,
//     'Access-Control-Allow-Origin': "*",
//     // 'Access-Control-Allow-Origin' : "https://api.revenuecat.com/v1/subscribers"
// }
});

export default apiAxios;