import React, {useEffect} from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Divider from '@mui/material/Divider';
// import Google from 'assets/images/icons/social-google.svg';
// import Apple from 'assets/images/icons/social-apple.png';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import validator from 'validator'
// import Logo from 'ui-component/Logo';
// import { Image } from '@material-ui/icons';
// import Atempo from '../assets/phoneLeft.png';
import { useState } from 'react';
// import { useContext } from 'react';
// import CustIdContext from 'hooks/customer_idContext';
// import FirebaseIdContext from 'hooks/FirebaseIdContext';
// import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
// import UserContext from 'hooks/userContext';
// import { useNavigate } from "react-router-dom";
// import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
// import axios from 'axios';
// import visu from '../../assets/images/fondvisu.png';
import visu from '../../assets/images/fondvisu.svg';

// import { makeStyles } from '@mui/styles';
import logo from '../../assets/images/zenego-logo.jpg';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
// import Icon from '@material-ui/core/Icon';
// import AppleIcon from '../../assets/images/apple-brands.svg';
// import GoogleIcon from '../../assets/images/google-brands.svg';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import '../../assets/scss/signUp.scss'
// import { alpha, styled } from '@mui/material/styles';
import logoAtp from '../../assets/images/logo-atempo.png';
import AuthLogin from '../pages/authentication/auth-forms/AuthLogin';

import CookieConsent from "react-cookie-consent";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ButtonMailto from './mailto';
// import firebase from 'firebase/compat/app';
// import apiAxios from '../../configAxios'
// import { authorization } from 'auth';
import { useMediaQuery } from '@mui/material';




// const useStyles = makeStyles({
//   grid: {
//   },
//   btn: {
//     backgroundColor: "#F98F83"
//   }
// });

const steps = ['Me connecter','Abonnement' ,'Paiment', 'Confirmation'];

// const firebaseConfig = {
//     apiKey: "AIzaSyBk-zDoAic8F4an-NzmkW34SRdgKBySkiI",
//     authDomain: "atempo-3da4a.firebaseapp.com",
//     databaseURL: "https://atempo-3da4a-default-rtdb.europe-west1.firebasedatabase.app",
//     projectId: "atempo-3da4a",
//     storageBucket: "atempo-3da4a.appspot.com",
//     messagingSenderId: "518853957556",
//     appId: "1:518853957556:web:8ebf394eb406bde0a544e1",
//     measurementId: "G-KS2TP69MHQ"
// };

//  const Copyright  = ( props ) => {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Atempo
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const theme = createTheme();

export default function SignUpSide( {mailto}) {
  // let navigate = useNavigate();
  
  // if(localStorage.getItem("currentstate") != '1'){
    
  //   navigate('/signin')
  // }
  //   let [email] = useState('');
  //   let [password] = useState('')
  //  let [name] = useState('')
  //  const {setCutstomerId} = useContext(CustIdContext)
  //  const { setFirebaseId} = useContext(FirebaseIdContext)
  //  const { setFirebaseEmail} = useContext(FirebaseEmailContext)
  //  const {currentUser, setCurrentUser} = useContext(UserContext)
    // const [emailError, setEmailError] = useState( '' )
    // const isMobile =  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    // const isMobile =  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    // var is_OSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    const [activeStep, setActiveStep] = useState(0);
    // const [open, setOpen]= useState(false)
    require('dotenv').config()
    // const SERVER_URL = process.env.REACT_APP_API_URL
    const matches = useMediaQuery('(min-width:600px)');
  

    // const testing = window.location;


  //  const validateEmail = (e) => {
  //   var email = e.target.value
  
  //   if (validator.isEmail(email)) {
  //     setEmailError(true)
  //     return true
  //   } else {
  //     setEmailError(false)
  //     return false
  //   }
  // }

  useEffect(() => {
    setActiveSteping()
  }, [activeStep])
  

const setActiveSteping = ()=> {
  const testing = window.location;

  if(testing.pathname === "/signin"){
    setActiveStep(0)
  }
}

  // const registred = () =>
  //  {
   
  //      if(emailError){
  //       const auth = getAuth();
       
  //       try{  
  //         if((password.length + 1) > 7){
  //         createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
  //          const user = userCredential.user;
  //                 setFirebaseId(user.reloadUserInfo.localId)
  //                 setFirebaseEmail(user.reloadUserInfo.email)
  //                 // ...
  //             })
  //             // .catch((error) => {
  //             //     const errorCode = error.code;
  //             //     const errorMessage = error.message;
  //             // });
  //             apiAxios.post(`create-customer`,authorization, {name : name, email: email.value}).then(res =>{
  //                  setCutstomerId(res.data.id)
  //             })
            
  //            navigate('../resume')
  //           //  navigate('../account')
  //         }else(alert('MDP trop court'))
  //     }catch(error){
  //             const errorCode = error.code;
  //                 const errorMessage = error.message;
  //                 alert(errorCode, errorMessage)
  //         } 
  //      }else{
  //          alert("invalid e-mail, e-mail invalide")
  //      }
     
      
  //   }

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   // eslint-disable-next-line no-console
  // };

  // const classes = useStyles();
  
  
  return (
    
    
    <>

      
      
    <Grid container component="main" sx={{
      height: '100%',
      backgroundColor: '#412E68',
      ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
         width: 1080,
      },
      ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        backgroundColor: 'white'
      }
    }}>
        <CssBaseline />
        
      
      <Grid
        className="animated fadeInLeft"
        spacing={5}
        component={Paper}
        elevation={3}
        md={5} 
        xs={12} 
        sx={{
          borderTopRightRadius: "10%",
          backgroundColor: 'white',
          justifyContent: 'center',
          borderTopLeftRadius: 0,
          marginLeft: -15,
          ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
            borderTopRightRadius: 0,
            marginLeft: 0
          },
          padding: 4,
          paddingLeft: { xs: 1, md: 10 },
        }}
      >
        <Box sx={{  mx: "auto", width: {xs: 150, md: 150}, display: { xs: 'block', md: 'none',  sm: 'none' } }} >
            <img
              style={{  width: "50%", marginLeft: '30%', marginBottom: 20 }}
              src={logoAtp} />
        </Box>
        <Typography sx={{ display: { xs: 'none', md: 'block',  sm: 'block', lg: 'block' },fontSize: 30, marginTop: 5, marginBottom: 5, fontWeight: "bold" }} color="primary">
              <strong>Me connecter</strong>
        </Typography>
        {/* <Button onClick={()=>{setOpen(!open)}}> Voir les CGU ici</Button>
        { open?
            <Typography variant="subtitle1" sx={{ display: { xs: 'none', md: 'block',  sm: 'block', lg: 'block' },marginTop: 5, marginBottom: 5 }}>
            En vous connectant vous acceptez les &nbsp;
            <a variant="subtitle1" style={{color: '#412E68'}} target="_blank" href={"https://www.atempo.app/cgv"}>Conditions Générales d'Utilisations</a>
            &nbsp;et reconnaissez avoir pris connaissance de la politique de 
            &nbsp;<a variant="subtitle1" style={{color: '#412E68'}} target="_blank" href={"https://www.atempo.app/vie-privee"}>confidentialité</a>
        </Typography>
          :<div></div>
        } */}
      
                              
         <AuthLogin/>
      
      </Grid>
      
      <Grid   xs={12} sm={8} md={9} 
        sx={{
              display: { xs: 'none', md: 'block' },
              backgroundImage: 'url('+ visu +')',
              backgroundRepeat: 'no-repeat',
              backgroundColor: '#412E68',
              backgroundSize: 'cover',
              backgroundPosition: 'right',
              marginRight:"-20%"
      }}
      
        >
          <Grid md={12}  sx={{ paddingTop: '20px' }}>
            <Box sx={{ width: '100%' }} >
            <Stepper  activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
                </Stepper>
              </Box>
          </Grid>
          
          <Box
            sx={{
            my: 8,
            mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
        >
           <img style={{ marginLeft: "13%", width: 200}} src={logo} width="200" />
        <Typography style={{ marginLeft: "15%", marginTop: 15,width: 250,textAlign: "center"}} color="white" variant="h3" component="h4">
          Il est temps de prendre soin de vous
          </Typography>
          
          </Box>
        </Grid>
        <CookieConsent
  location="bottom"
  buttonText="J'accepte"
  cookieName="CookieAtempoStripe"
  style={{ background: "#221835",  fontSize: "16px", color: 'lightGrey', fontFamily: 'HelveticaNeue' }}
  buttonStyle={{backgroundColor:"#F98F83", color: "black", fontSize: "13px" }}
  expires={150}
>
Nous utilisons des cookies pour comprendre comment vous interagissez avec notre site. En acceptant, vous consentez à notre utilisation de ces cookies.{" "}
  <span style={{ fontSize: "12px" }}><a style={{color:"white"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Politique de confidentialité</a></span>
</CookieConsent>
      </Grid>
      {matches?
       <div style={{marginTop:'2%'}}>
       <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
            <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr"/>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>
       </div>
       <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'40%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
     </div>
        :
        <div style={{marginTop:'2%'}}>
        <div style={{marginTop:'0%', marginLeft:"6%"}}>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/mentions-l%C3%A9gales"}>Mentions Légales</a> */}
             {/* <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" /> */}
             {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app"}>Retour site web</a> */}
   
        </div>
        <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/cgv"}>Conditons Générales d'Utilisation</a> */}
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/vie-privee"}>Vie Privée</a> */}
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
             <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
             {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app"}>Retour site web</a> */}
   
        </div>
        <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/cgv"}>Conditons Générales d'Utilisation</a> */}
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/vie-privee"}>Vie Privée</a> */}
            {/* <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} target="_blank" href={"https://www.atempo.app/mentions-l%C3%A9gales"}>Mentions Légales</a> */}
             {/* <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" /> */}
             <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>
   
        </div>
        <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'12%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
      </div>
      }
      </>
  
  );
}