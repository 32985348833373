import React from "react";

export const UserContext = React.createContext('');

// export const UserProvider = ({children}) => {
//     const [currentUser, setCurrentUser] = useState(null);

//     useEffect(()=>{
//         app.auth().onAuthStateChanged(setCurrentUser);
//         console.log(currentUser + "current user")
//     }, []);

//     return(
//         <UserProvider value={currentUser}>
//             {children}
//         </UserProvider>
//     )
// }

export default UserContext;