import { useContext, useState } from 'react';
// import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';

// material-ui
// import { useTheme } from '@mui/material/styles';
import {
    Alert,
    // Box,
    Button,
    Collapse,
    // Divider,
    // FormControl,
    // FormHelperText,
    Grid,
    // TextField,
    // Typography,
    useMediaQuery,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
// third party
// import * as Yup from 'yup';
// import { Formik } from 'formik';
import { getAuth, signOut } from "firebase/auth";
// project imports
// import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
// import { alpha, styled } from '@mui/material/styles';

import '../../../../firebase'

import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
// import AuthButton from './AuthButton';
// import axios from 'axios';
import UserContext from 'hooks/userContext';
import CustIdContext from 'hooks/customer_idContext';
import FirebaseIdContext from 'hooks/FirebaseIdContext';
import CouponActive from 'hooks/couponActive';
import CouponContext from 'hooks/couponContext';
import apiAxios from 'configAxios';
import { authorization } from 'auth';
import Reabonnement from 'hooks/reabonnement';
import IsStripePlatform from 'hooks/isStripePlatform';
import CancelAtPeriod from 'hooks/cancelAtPeriod';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// ============================|| FIREBASE - LOGIN ||============================ //

const Account = ({ ...others }) => {
    let navigate = useNavigate();
    if(localStorage.getItem("currentState") === '1' ||localStorage.getItem("currentState") === '2' || localStorage.getItem("currentState") === '3'){
        
      }else navigate('/signin')
    
    // const theme = useTheme();
    // const scriptedRef = useScriptRef();
    // const customization = useSelector((state) => state.customization);
    const {setFirebaseEmail} = useContext(FirebaseEmailContext)
    const {currentUser, setCurrentUser} = useContext(UserContext)
    const {setCutstomerId} = useContext(CustIdContext)
    const { setFirebaseId} = useContext(FirebaseIdContext)
    const { setCouponActive} = useContext(CouponActive)
    const {setCoupon} = useContext(CouponContext)
    const {seReabonner}= useContext(Reabonnement)
    const [open, setOpen] = useState(false);
    const {stripePlatformIs, setStripePlatformIs} = useContext(IsStripePlatform)
    const {cancelAtPeriod, setCancelAtPeriod}=useContext(CancelAtPeriod)
    // const [usering, setUsering] = useState(localStorage.getItem('currentUser'))
    const matches = useMediaQuery('(min-width:600px)');
    const [openn, setOpenn] = useState(false);
    require('dotenv').config()
    // const SERVER_URL = process.env.REACT_APP_API_URL

 const handleClose = () => {
    setOpenn(false);
  };

    useEffect(() => {
        // console.log(currentUser, 'RRRRRRRRRRR')
      }, [currentUser])

    const deleteSubscription = async() => {
      if (typeof(currentUser)==="string"){
        try{
            apiAxios.post('update/subscription',{'sub_id':currentUser, 'cancel_at_period': true}, authorization).then((res)=>{
                // console.log(res.data)
                // console.log('GOOOOOOOOOOOOOOOOOOOOOOOOOOO')
              }).catch((err)=>{
                // console.log(err)
              })
            // const res = await apiAxios.post(`delete/subscription`, {"subscription_id": currentUser}, authorization)
            setOpen( true )
            setCancelAtPeriod(true)
            setOpenn(false)
        }catch(err){
            // console.log(err)
        }
    
  }else{
    try{
      apiAxios.post('update/subscription',{'sub_id':currentUser.abonnement.sub_id, 'cancel_at_period': true}, authorization).then((res)=>{
        // console.log(res.data)
        // console.log('GOOOOOOOOOOOOOOOOOOOOOOOOOOO')
      }).catch((err)=>{
        // console.log(err)
      })
    // const res = await apiAxios.post(`delete/subscription`, {"subscription_id": currentUser}, authorization)
    setOpen( true )
    setCancelAtPeriod(true)
    setOpenn(false)
    }catch(err){

    }
  }
    }
    // const renouvellement = () =>{
    //     try{
    //         apiAxios.post('update/subscription',{'sub_id':currentUser, 'cancel_at_period': false}, authorization).then((res)=>{
    //             // console.log(res.data)
    //             // console.log('GOOOOOOOOOOOOOOOOOOOOOOOOOOOD')
    //           }).catch((err)=>{
    //             // console.log(err)
    //           })
    //         // const res = await apiAxios.post(`delete/subscription`, {"subscription_id": currentUser}, authorization)
    //         setOpen( true )
    //         setCurrentUser('')
    //     }catch(err){
    //         // console.log(err)
    //     }
    // }

    const logout = async () => {
        const auth = getAuth();
            signOut(auth).then(async() => {
                setFirebaseEmail('')
                setCurrentUser('')
                setCutstomerId('')
                setFirebaseId('')
                setCouponActive('')
                setCoupon('')
               await localStorage.setItem('currentState',"0");
                localStorage.removeItem('currentUser');
                navigate('/signin')
            // Sign-out successful.
            }).catch((error) => {
            // An error happened.
            });
       
    }

      //  console.log(cancelAtPeriod,"data 2")
        //      const userStorage ={
        //       "currentUser" : firebaseEmail,
        //       "currentUserId" : firebaseId,
        //       "Coupon" : true
        //   }
        //     try{
        //       localStorage.setItem("currentUser", JSON.stringify(userStorage))
        //     }catch(err){
        //         console.log(err)
        //     }
        setStripePlatformIs(true)
    useEffect( () =>
    {
        setTimeout(() => {
            // console.log('This will run after 1 second!')
            setOpen(false)
          }, 3000);
        
    }, [open])
   
    return (
        <>
         <Dialog
        open={openn}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">
          {"Votre abonnement"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous confirmez arrêter le renouvellement automatique de votre abonnement ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Non</Button>
          <Button onClick={()=> {deleteSubscription()}} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
        {matches?
        <div>
         
        </div>
        
          :
        <div></div>
        }
        {matches?
        <Grid style={{height: "30%",display: 'flex', justifyContent: 'center', alignItems:'flex-end'}}>
            
        <Grid>
        <Collapse in={open}>
                <Alert
     
      sx={{ mb: 19 }}
    >
      Demande prise en compte, votre abonnement ne sera pas renouvelé ! 
    </Alert>
    </Collapse>
            
                        <AnimateButton>
                        <Button
                            disableElevation
                            // fullWidth
                            size="small"
                            type="submit"
                            // variant="contained"
                        // color="primary"
                            // style={{marginLeft:'-20%'}}
                            sx={{ textTransform: 'inherit' }}
                            onClick={logout}
                        >
                            Se déconnecter
                        </Button>
                </AnimateButton>
         
        </Grid>
         <Grid>
                
         
        
        

        {!cancelAtPeriod && stripePlatformIs    && <Button
                disableElevation
                // fullWidth
                size="small"
                type="submit"
                // variant="contained"
                           
                style={{ width:"180%", marginLeft: "-44%", marginBottom:'-21%'}}
                sx={{ textTransform: 'inherit' }}
                onClick={() => setOpenn(true)}
                
            >
                Annuler le renouvellement automatique
            </Button>}
        </Grid>
        </Grid>
        :
        
        <Grid xs={12} style={{height: "70%", width:"500%",display: 'block', justifyContent: 'center', alignItems:'flex-end'}}>
             <Collapse in={open} style={{marginTop:"0%"}}>
                <Alert
     
      
    >
      Vous êtes désabonné ! Votre abonnement ne sera pas renouvelé !
    </Alert>
    </Collapse>
        <Grid>
       <div style={{display:"flex", justifyContent:"flex-start"}}>
       <AnimateButton>
      
                        <Button
                            disableElevation
                            // fullWidth
                            size="small"
                            type="submit"
                            // variant="contained"
                        // color="primary"
                            style={{marginBottom:'-40%'}}
                            sx={{ textTransform: 'inherit' }}
                            onClick={logout}
                        >
                            Se déconnecter
                        </Button>
                </AnimateButton>
       </div>
            
                      
               
        </Grid>
        
         <Grid>
        
        
                
             {seReabonner?
                <div>
                      
                </div>
                :
                <div style={{width:"200%",display:'flex', jusityContent:'center'}}>
                       {currentUser && stripePlatformIs &&
                       <div>
                           {cancelAtPeriod?
                           <div>
                               </div>
                        :
                        <div style={{display:'flex', jusityContent:'flex-start', marginLeft:"8%"}}>
                        <AnimateButton>
                          <Button
                        disableElevation
                        size="small"
                        type="submit"
                        style={{ width:"180%", marginLeft: "-44%",marginTop:'-35%'}}
                        sx={{ textTransform: 'inherit' }}
                        onClick={() => setOpenn(true)}
                    >
                        Annuler le prochain renouvellement
                    </Button>
                        </AnimateButton>
                        </div>
                        }
                      
            </div>
            }
                </div>
             }   

     
        </Grid>
        </Grid>
    }
        </>
    );
};

export default Account;
