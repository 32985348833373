/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from 'react';
// import {useHistory} from 'react-router-dom';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Divider from '@mui/material/Divider';
// import Google from 'assets/images/icons/social-google.svg';
// import Apple from 'assets/images/icons/social-apple.png';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
// import { createTheme } from '@mui/material/styles';
// import validator from 'validator'
// import Logo from 'ui-component/Logo';
// import { Image, Label } from '@material-ui/icons';
// import Atempo from '../assets/phoneLeft.png';
import { useState } from 'react';
import { useContext } from 'react';
// import CustIdContext from 'hooks/customer_idContext';
import FirebaseIdContext from 'hooks/FirebaseIdContext';
// import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
import UserContext from 'hooks/userContext';
import { useNavigate } from "react-router-dom";
// import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import axios from 'axios';
// import visu from '../../assets/images/fondvisu.svg';
// import { makeStyles } from '@mui/styles';
// import logo from '../../assets/images/logoviolet.jpg';
// import Card from '../../assets/images/DLAtempo.png'
import Success from '../../assets/images/Elipse.png'
import Smartphone from '../../assets/images/Group 51.png'
import CercleInfo from '../../assets/images/Cercles informations.png'
import Confeties from '../../assets/images/confeties.png'
// import Logo from '../../assets/images/atempo-logo.png'
import H1 from '../../assets/images/TexteH1.png'
import AppStore from '../../assets/images/AppStore.png'
import GooglePlay from '../../assets/images/GooglePlay.png'
// import ValidAbo from '../../assets/images/validateAbo.png'
// import DeleteIcon from '@mui/icons-material/Delete';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
// import Icon from '@material-ui/core/Icon';
// import AppleIcon from '../../assets/images/apple-brands.svg';
// import GoogleIcon from '../../assets/images/google-brands.svg';
// import AnimateButton from 'ui-component/extended/AnimateButton';
import '../../assets/scss/signUp.scss'
// import { styled } from '@mui/material/styles';
import logoAtp from '../../assets/images/logo-atempo.png';
// import logoPuces from '../../assets/images/cercle-noir.png';
// import AuthLogin from '../pages/authentication/auth-forms/AuthLogin';
// import { useSelector } from 'react-redux';
import { LinearProgress, Step, StepLabel, Stepper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery'
import { getDatabase, ref, set } from "firebase/database";
import InvoiceContext from 'hooks/InvoiceContext';
// import logoAtempo from '../../assets/images/atempo-logo.png'
import apiAxios from 'configAxios';
import { authorization } from 'auth';
// import CustomizedSteppers from './stepper';
import ButtonMailto from './mailto';
// const useStyles = makeStyles({
//   grid: {
//   },
//   btn: {
//     backgroundColor: "#F98F83"
//   }
// } );
// const sautDeLigne = {''};
const steps = [
  {
    label: `Téléchargez l'application Zenego sur votre smartphone.`,
    
  },
  {
    label: 'Connectez-vous avec votre e-mail et mot de passe utilisés sur ce site.',
  
  },
  {
    // label: 'Create an ad',
    label: `Partez à la découverte de nouvelles pratiques, de façon simple et ludique.`,
  },
];


// const firebaseConfig = {
//     apiKey: "AIzaSyBk-zDoAic8F4an-NzmkW34SRdgKBySkiI",
//     authDomain: "atempo-3da4a.firebaseapp.com",
//     // databaseURL: "https://atempo-3da4a-default-rtdb.europe-west1.firebasedatabase.app",
//     databaseURL: "https://atempo-3da4a.europe-west1.firebasedatabase.app/",
//     projectId: "atempo-3da4a",
//     storageBucket: "atempo-3da4a.appspot.com",
//     messagingSenderId: "518853957556",
//     appId: "1:518853957556:web:8ebf394eb406bde0a544e1",
//     measurementId: "G-KS2TP69MHQ"
// };


// const steps = ['Se connecter','Récapitulatif' ,'Paiment', 'Confirmation'];

//  const Copyright  = ( props ) => {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Atempo
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }


export default function Successing() {
  let navigate = useNavigate();
// const theme = createTheme();
if(localStorage.getItem("currentState") === '3'){
  
}else navigate('/signin')

    // let [email] = useState('');
    // let [password] = useState('')
  //  let [name] = useState('')
  //  const { setCutstomerId} = useContext(CustIdContext)
   const {firebaseId} = useContext(FirebaseIdContext)
  //  const { setFirebaseEmail} = useContext(FirebaseEmailContext)
   const {currentUser} = useContext(UserContext)
    // const [emailError, setEmailError] = useState( '' )
    // const customization = useSelector((state) => state.customization);
    // const isMobile =  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    const database = getDatabase();
  const [invoice, setInvoice] = useState('');
  const [forgoten, setForgoten] = useState(false)
  const {link,setLink} = useContext(InvoiceContext)
  const [activeStep, setActiveStep] = React.useState(0);
  const matches = useMediaQuery('(min-width:600px)');
  require('dotenv').config()
  // const SERVER_URL = process.env.REACT_APP_API_URL
  // var today = new Date();
  // function now(){
  //   return new Date().getTime()
  // }
  const date = Math.floor(new Date().getTime() / 1000);
// const day = today.getDate();
// const mois = today.getMonth() +1;
// const year = today.getFullYear();
  const Push = () => {
    try{
      set(ref(database, 'users/' + firebaseId), {
        abonnement: {
          name: "annuel",
          start: date ,
          platform: "web",
          sub_id: currentUser,
        }
      })
    }catch(error){
      // console.log(error)
    }
    
  }
 
    
  
 
    

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
// console.log(currentUser, "currentUser")
  //  REVENUE CAT
  const data = {
    "fetch_token": currentUser,
    "app_user_id": firebaseId, // remplacer par l'id firebase de type UID
    // "user_email": firebaseEmail
}
const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer strp_TnvvFRazRTZHGhXLfoJaLKHxXMs'
}

const sendDataToRc = () => {
  axios.post('https://api.revenuecat.com/v1/receipts', data, {
  headers: headers,    
})      
.then((response) => {
  Push()
 return
})
.catch((error) => {
//  console.log(error)
 return
})
  
  //  const validateEmail = (e) => {
  //   var email = e.target.value
  
  //   if (validator.isEmail(email)) {
  //     setEmailError(true)
  //     return true
  //   } else {
  //     setEmailError(false)
  //     return false
  //   }
  // }
}
 useEffect(() => {
  apiAxios.post(`current/subscription`, {sub_id: currentUser}, authorization).then((res)=> {
   setInvoice(res.data.latest_invoice);
   getInvoice()
});
const getInvoice = async()=> {
  await apiAxios.post(`get-invoice`, {intent_id: invoice}, authorization).then((res)=> {
     setLink(res.data.invoice_pdf)
   })
  }
 }, [invoice, link]) 

   useEffect(() => {
      sendDataToRc()
}, [firebaseId])

useEffect(() => {
  if(activeStep < 4){
    setTimeout(() => {
      setActiveStep(activeStep +1)
    }, 3000);
  }
 
}, [activeStep])


  // const profile = () => {
  //   navigate('/signin')
  // }

  // const registred = () =>
  //  {
   
  //      if(emailError){
  //       const auth = getAuth();
       
  //       try{  
  //         if((password.length + 1) > 7){
  //         createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
  //          const user = userCredential.user;
  //                 setFirebaseId(user.reloadUserInfo.localId)
  //                 setFirebaseEmail(user.reloadUserInfo.email)
  //             })
  //             apiAxios.post(`create-customer`, {name : name, email: email.value}, authorization).then(res =>{
  //                  setCutstomerId(res.data.id)
  //             })
            
  //            navigate('./product')
  //         }else(alert('MDP trop court'))
  //     }catch(error){
  //             // console.log(error + 'error');
  //             const errorCode = error.code;
  //                 const errorMessage = error.message;
  //                 // console.log(errorCode, errorMessage)
  //                 alert(errorCode, errorMessage)
  //         } 
  //      }else{
  //          alert("invalid e-mail, e-mail invalide")
  //      }
  //   }

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   // eslint-disable-next-line no-console
  // };

  // const classes = useStyles();

  // const styles = theme => ({
  //   root: {
  //     width: "90%"
  //   },
  //   button: {
  //     marginRight: theme.spacing.unit
  //   },
  //   completed: {
  //     display: "inline-block"
  //   },
  //   instructions: {
  //     marginTop: theme.spacing.unit,
  //     marginBottom: theme.spacing.unit
  //   },
  //   icon: {
  //     color: "white !important"
  //   }
  // });

  // const colorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  //   zIndex: 1,
  //   color: '#fff',
  //   width: 50,
  //   height: 50,
  //   display: 'flex',
  //   borderRadius: '50%',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   ...(ownerState.active && {
  //     backgroundImage:
  //       'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  //     boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  //   }),
  //   ...(ownerState.completed && {
  //     backgroundImage:
  //       'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  //   }),
  // }));
  
  
  return (
    <>
    {matches?
 <div style={{display:"flex", justifyContent:"flex-end", width:"20%", marginLeft:"105%", height: "3%"}}>
 <p style={{fontSize:"15px"}}><a style={{textDecoration:"none", color:"#412E68"}} rel="noreferrer" target="_blank" href='https://zenego.fr/'>Retour site web</a></p>
   <Button style={{width:"5%"}} rel="noreferrer" target="_blank" href='https://zenego.fr/'>
    
 <img style={{width:"100%"}} src={logoAtp}/>

   </Button>

 </div>
  :
  <div style={{display:"flex", justifyContent:"flex-end", width:"50%", marginLeft:"65%", height: "3%"}}>
  <p style={{fontSize:"13px", marginRight:'-5%'}}><a style={{textDecoration:"none", color:"#412E68"}} rel="noreferrer" target="_blank" href='https://zenego.fr/'>Retour site web</a></p>
    <Button style={{width:"5%"}} rel="noreferrer" target="_blank" href='https://zenego.fr/'>

    </Button>

  </div>
    }
   
    <Grid container component="main"
     sx={{
      height: '85%',
      backgroundColor: 'White',
      ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        backgroundImage: 'url('+ Success +')',
         width: 880,
      },
      ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: 280,
        backgroundSize: '50%',
      },
      display: 'flex',
      justifyContent: 'center'
    }} >
      <CssBaseline />
      
      
      <Grid   xs={12} sm={12} md={12} 
        sx={{
              display: { xs: 8, md: 'block' },
              backgroundImage: 'url('+ Success +')',
              backgroundRepeat: 'no-repeat',
              backgroundColor: 'white',
              backgroundSize: '100%',
              backgroundPosition: 'bottom',
              ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
                width: 880,
             },
             ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
              width: 180,
              backgroundImage: 'none',
              display: "flex",
              flexDirection: "column"
            },
      }}
      
      >
       

<Grid  style={{

}}>


</Grid>

        <Grid>
        <img style={{ width: "40%", marginLeft: "34%"}} src={Confeties} width="200" />
        
        </Grid>


        <Grid sx={{
           ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            width: 880,
         },
         ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
         display: "flex",
         alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column"
        },
        }} style={{marginBottom: "15%"}}>
       
        </Grid>

        <Grid xs={6} sm={12} md={12}sx={{
          ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
           display: "block",
           justifyContent: "flex-start",
          },
          ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
          }
        }}>
       <div style={{marginBottom: "-30%", width: "100%"}}
        sx={{ 
        ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
          width: "150px",
          display: "block"
       },
       ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: "150%"
       }
        }}
       >
         { matches ? 
         <div style={{marginTop: "-30%"}}>
                      <img style={{ width: "25%" }}
                      sx={{ ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
                       marginLeft: "-10px"
                      },
                      ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
                        marginLeft: 90,
                      }
                    }}
                      src={Smartphone} width="200" />
                      <img style={{ width: "40%", marginLeft: "5%", marginBottom: "5%"}} src={H1} width="200" />
                      <div style={{marginLeft: "25%"}}>
                      <p style={{color:"#412E68 "}}>Vous faites maintenant partie de la communauté Zenego.</p>
                      <br/>
                 <p style={{marginTop:"-4%", color:"#412E68 "}}> Un e-mail avec un justificatif vient de vous être envoyé.</p>
                     </div>
     <div style={{marginLeft: "64%",marginRight: "3%", width: "16%"}}>
    <Button
    sx={{ textTransform: 'inherit', textDecoration: 'none', backgroundColor: "#FFC498" ,
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
     width: "20%",
    height: "50%",
    fontSize: "10px",
    padding: "8%",
   },
   ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
   marginBottom:"-7%",
   marginLeft: "5%"
   }
    }}
   variant="contained" onClick={() => navigate('/account') }
   style={{ backgroundColor: "#F98F83"}}
   >Mon compte</Button>
   </div>
   <div style={{width: "12.7%", marginLeft: "24%",marginRight:"10%", marginTop: "-3%"}}>
    <Button 
     variant="contained"
     sx={{ textTransform: 'inherit', textDecoration: 'none', backgroundColor: "#FFC498" ,
     ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
     display: "flex",
     justifyContent: "center",
     alignItems: "column",
     marginLeft: "34%",
     height: "58%",
     fontSize: "10px",
     width:'505%',
    },
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    }
     }} href={link} onClick={() => { setForgoten(true)
      ;setTimeout( () =>
      {
        setForgoten(false)
       
        
      }, 4000)
     }} style={{textDecoration: 'none', textTransform: 'inherit', backgroundColor: "#F98F83"}}>
      Ma facture </Button>
      {forgoten && <LinearProgress style={{width:"98%"}} color="secondary" />}
      </div>
                      </div>
                      
                           : <div style={{marginTop: "-20%"}}> 
                           <img style={{ width: "55%", marginBottom: "-20%" }}
                      sx={{ ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
                       marginLeft: "-10px"
                      },
                      ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
                        marginLeft: 94,
                      }
                    }}
                      src={Smartphone} width="200" />
                      <img style={{ width: "160%", marginLeft: "50%", marginBottom: "55%", marginTop: "-19%"}} src={H1} width="200" />
                      
                           </div> }
        <Grid
        sx={{
        ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
           width: "200%",
           marginBottom: "-7%",
           marginLeft: "10%",
           height: "40%",
           color: "#412E68"
        },
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
          marginLeft: "42.5%",
          width: "50%",
          marginTop: "27.7%"
        }
        }}>

           { matches ? 
           <div style={{marginTop:"-35%", height:"120%"}}>
           
              <p style={{padding: "3%", marginLeft: '21%', color:"#F98F83", marginBottom:"-1%", marginTop:"0%"}}>Vos prochaines étapes : </p>

            <div style={{display:"flex", flexDirection:"column"}}>
              <div style={{ marginBottom:"5%", flexDirection:"initial", alignItems:"center", justifyContent:"space-around"}}>
              <p style={{color:"white"}}>- Téléchargez l'application Zenego sur votre smartphone.</p>
              </div>
              <div style={{ marginBottom:"5%", display:"flex", flexDirection:"initial", alignItems:"center", justifyContent:"space-around"}}>
              <p style={{color:"white"}}>- Connectez-vous avec votre e-mail et mot de passe utilisés sur ce site.</p>
              </div>
              <div style={{ marginBottom:"5%", display:"flex", flexDirection:"initial", alignItems:"center", justifyContent:"space-around"}}>
              <p style={{color:"white"}}>- Partez à la découverte de nouvelles pratiques, de façon simple et ludique.</p>
              </div>
            </div>
          
          
                         
                          </div> :
                          <div style={{height: "50%", marginTop: "-20%"}}>
                            <div style={{marginTop: "20%"}}> 
                            <p 
        
        style={{ fontSize: "90%", color : "#412E68 ",fontFamily:'sans-serif', opacity: "0.7", fontWeight: 500}}>Vous faites maintenant partie de la communauté Zenego.

Un e-mail avec un justificatif vient de vous être envoyé.

Partez à la découverte de nouvelles pratiques, de façon simple et ludique !</p>
                           </div>
                          <Box style={{ borderRadius: "13px", height: "50%"}}  sx={{ maxWidth: 400, marginBottom:'-85%' }}>
                          <p style={{padding: "3%", marginLeft: '12%', width:"100%"}}>Vos prochaines étapes : </p>
                        <Stepper style={{padding: "3%"}}  activeStep={activeStep} orientation="vertical">
                          {steps.map((step, index) => (
                            <Step style={{color:"white"}} key={step.label}>
                              <StepLabel
                                optional={
                                  index === 2 ? (
                                    <Typography variant="caption"></Typography>
                                  ) : null
                                }
                              >
                                {step.label}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      
                      </Box>
                      </div>
                           
                            }
       
        </Grid>
       </div>

        </Grid>
        { matches ? 
   <Grid   sx={{ 
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
      marginRight: "66%",
      marginTop: "27%",
      display: "flex",
      marginLeft: "0%",
      width: "100%",
   },
   ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
     marginTop: "27%",
     marginLeft: "4%"
   }
    }}>
     
    </Grid>
        : <div style={{marginTop:"7%"}}>
           <Grid   sx={{ 
        ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
          marginRight: "66%",
          marginTop: "87%",
          display: "flex",
          marginLeft: "4%",
          width: "100%",
       },
       ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
         marginTop: "27%",
         marginLeft: "4%"
       }
        }}>
        <Button
        sx={{ textTransform: 'inherit', textDecoration: 'none', backgroundColor: "#FFC498" }}
       variant="contained" onClick={() => navigate('/account', { state:{
        firebaseId: firebaseId
      }}) }
       style={{marginRight: "3%", backgroundColor: "#412E68 ", width: "40%", fontSize: "10px"}}
       >Mon compte</Button>
        <Button 
         variant="contained"
        //  color="primary" 
         sx={{ textTransform: 'inherit', textDecoration: 'none', backgroundColor: "#FFC498" ,
         }} href={link} onClick={() => { setForgoten(true)
          ;setTimeout( () =>
          {
            setForgoten(false)
           
            
          }, 4000)
         }} style={{textDecoration: 'none', textTransform: 'inherit', backgroundColor: "#412E68 ",marginTop: "0%", width: "40%", fontSize: "10px", marginLeft:"15%"}}>
          Ma facture</Button>
          {forgoten && <LinearProgress color="secondary" />}
        </Grid>
        </div> 
}
       
        { matches ? 
                           <Grid style={{marginTop: "-10%"}} sx={{
                            ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key

                             
                            
                            },
                            ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key

                              marginTop:"-30%"
                            }
                            }}>
                            <img style={{ width: "40%", marginTop:"-28.9%"}} src={CercleInfo}/>
                            <div style={{display:"flex", justifyContent:"center", width:"100%", marginTop: "-7%", marginLeft:"19%"}}>
                            <a style={{width: "17%"}} rel='noreferrer' target="_blank" href="https://play.google.com/store/apps/details?id=com.taiwa.manuel.atempo&gl=FR">
                            <img style={{ width: "100%", marginLeft: "0", backgroundColor: "transparent",marginTop:"-5%"}} src={GooglePlay}/> 
                            </a>

                            <a style={{width: "17%"}} rel='noreferrer' target="_blank" href="https://apps.apple.com/fr/app/atempo-relaxation-bien-%C3%AAtre/id1565493538">
                            <img style={{ width: "80%", height: '40px', marginBottom:'-13%'}} src={AppStore}/>
                            </a>
                    
                            </div>
                           
                          
                          
                            </Grid>
                           : <div style={{width: "120%", display: "block", alignItems:"center", justifyContent: "center", flexDirection:"column", marginLeft:'-7%'}}>
                             <a style={{ width: "50%", marginLeft:"5.9%", marginTop: "-78%"}} rel="noreferrer"  target="_blank" href="https://play.google.com/store/apps/details?id=com.taiwa.manuel.atempo&gl=FR">
                             <img style={{ width: "39%", height: '55px', marginLeft: "0%", backgroundColor: "transparent",}} src={GooglePlay}/>

                              </a> 
                              <a style={{ width: "50%"}} rel="noreferrer" target="_blank" href="https://apps.apple.com/fr/app/atempo-relaxation-bien-%C3%AAtre/id1565493538">
                              <img style={{ width: "34%", height: '37px',marginBottom:"2.5%", marginLeft:'13%' }}  src={AppStore}/>
                              </a>
                           </div> }
       
        <Grid style={{display: "block"}}>
        
        </Grid>
     
        </Grid>
        
      </Grid>
      {matches?
       <div style={{marginTop:'2%'}}>
       <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
           <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
            <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr"/>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>
       </div>
       <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'40%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
     </div>
        :
        <div style={{marginTop:'2%', width:"108%"}}>
        <div style={{marginTop:'0%', marginLeft:"6%"}}>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/cgv"}>Conditons Générales d'Utilisation</a>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/vie-privee"}>Vie Privée</a>
        </div>
        <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr/mentions-l%C3%A9gales"}>Mentions Légales</a>
             <ButtonMailto  label="Contactez-nous" mailto="mailto:contact@zenego.fr" />
        </div>
        <div style={{marginTop:'0%', display:'flex', justifyContent:'center', alignItems:'flex-start', marginLeft:"6%"}}>
            <a style={{fontSize:"60%", color:"grey", marginRight:"15px", fontFamily:'sans-serif', opacity: "0.7"}} rel="noreferrer" target="_blank" href={"https://www.zenego.fr"}>Retour site web</a>
   
        </div>
        <p style={{fontFamily:'sans-serif', fontSize:"13px", color:"black",marginLeft:'12%', marginTop:"3%" }}>© 2022 by Zenego - L'Application du bien-être</p>
      </div>
      }
      </>
  );
}
