import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import { getDatabase } from "firebase/database";

//database test
// const devEnv ="https://atempo-3da4a.europe-west1.firebasedatabase.app"
//Database main
// const mainEnv="https://atempo-3da4a-default-rtdb.europe-west1.firebasedatabase.app";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBk-zDoAic8F4an-NzmkW34SRdgKBySkiI",
  authDomain: "atempo-3da4a.firebaseapp.com",
  databaseURL: "https://atempo-3da4a-default-rtdb.europe-west1.firebasedatabase.app",
  // databaseURL: "https://atempo-3da4a.europe-west1.firebasedatabase.app/",
  projectId: "atempo-3da4a",
  storageBucket: "atempo-3da4a.appspot.com",
  messagingSenderId: "518853957556",
  appId: "1:518853957556:web:8ebf394eb406bde0a544e1",
  measurementId: "G-KS2TP69MHQ"
})

export const auth = app.auth()
// const database = getDatabase(app);
export default app