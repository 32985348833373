// import { lazy } from 'react';

// project imports
// import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
// import { Success } from 'views/pages/authentication/authentication3/Success.jsx';
// import CardForm from 'stripeElements/Card';
// import ProductDisplay from 'views/pages/authentication/authentication3/ProductDisplay';
// import CheckoutForm from 'views/checkoutForm/CheckoutForm';
// import SignInSide from 'views/Desktop/createAccount';
// import SignUpSide from 'views/Desktop/createAccount';
// import Products from 'views/Desktop/productPage';
// import SignUp from 'views/Desktop/signUp';
// import SignIn from 'views/Desktop/signIn';
// import MyAccount from 'views/Desktop/account';
// import Resume from 'views/Desktop/resume';

// import Payment from 'views/Desktop/payment';
// import Successing from 'views/Desktop/success';
// import WebsiteVue from 'views/Desktop/websiteVue';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

const SignIn = Loadable(lazy(() => import('views/Desktop/signIn')))
const SignUp = Loadable(lazy(() => import('views/Desktop/signUp')))
const MyAccount = Loadable(lazy(() => import('views/Desktop/account')))
const Resume = Loadable(lazy(() => import('views/Desktop/resume')))
const Payment = Loadable(lazy(() => import('views/Desktop/payment')))
const Successing = Loadable(lazy(() => import('views/Desktop/success')))
const WebsiteVue = Loadable(lazy(() => import('views/Desktop/websiteVue')))



// login option 3 routing

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
         {
            path: '/',
            element: <SignIn />
        },
        {
            path: '/signin',
            element: <SignIn />
        },
        {
            path: '/signup',
            element: <SignUp />
        },
        {
            path: '/account',
            element: <MyAccount />
        },
        {
        
            path: '/resume',
            element: <Resume/>
        },
        {
        
            path: '/payment',
            element: <Payment/>
        },
        {
        
            path: '/success',
            element: <Successing/>
        },
        {
        
            path: '/asset/WebsiteVue.png',
            element: <WebsiteVue/>
        },
    ]
};

export default AuthenticationRoutes;
