import React, { useContext, useEffect, useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement} from '@stripe/react-stripe-js';
// import  {useMessages} from './StatusMessages';
import { Alert, AlertTitle, Button, Collapse, LinearProgress } from '@mui/material';
import { useNavigate  } from "react-router-dom";
import { Navigation } from '@material-ui/icons';
import { Success } from 'views/pages/authentication/authentication3/Success';
import UserContext from 'hooks/userContext';
import CustIdContext from 'hooks/customer_idContext';
import CouponContext from 'hooks/couponContext';
import FirebaseEmailContext from 'hooks/FirebaseEmailContext';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import apiAxios from '../configAxios'
import { authorization } from 'auth';
import { ErrorMessage } from 'formik';
import paiement from '../assets/images/logo-cb.jpeg';
import mastercard from '../assets/images/MasterCard_Logo.svg.png';
import visa from '../assets/images/Visa_Inc._logo.svg.png';
import PromoId from 'hooks/promoid';
import CouponIdContext from 'hooks/couponId';
import CardErrorContext from 'hooks/CardErrorContext';
// const axios = require('axios');


// const CARD_OPTIONS = {
//   iconStyle: "solid",
//   style: {
//     base: {
//       iconColor: "#c4f0ff",
//       color: "#fff",
//       fontWeight: 500,
//       fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
//       fontSize: "16px",
//       fontSmoothing: "antialiased",
//       ":-webkit-autofill": {
//         color: "#fce883"
//       },
//       "::placeholder": {
//         color: "#87bbfd"
//       }
//     },
//     invalid: {
//       iconColor: "#ffc7ee",
//       color: "#ffc7ee"
//     }
//   }
// };


// const Field = ({
//   label,
//   id,
//   type,
//   placeholder,
//   required,
//   autoComplete,
//   value,
//   onChange
// }) => (
//   <div className="FormRow">
//     <label htmlFor={id} className="FormRowLabel">
//       {label}
//     </label>
//     <input
//       className="FormRowInput"
//       id={id}
//       type={type}
//       placeholder={placeholder}
//       required={required}
//       autoComplete={autoComplete}
//       value={value}
//       onChange={onChange}
//     />
//   </div>
// );
const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  // const [messages, addMessage] = useMessages();
  const [state, setState] = useState(false)
  const [email] = useState('');
  const { setCurrentUser} = useContext(UserContext)
  const [clientSecret, setClient_secret] = useState('')
  const [ setStatus] = useState('')
  const {customerId, setCutstomerId} = useContext(CustIdContext)
  const {firebaseEmail} = useContext(FirebaseEmailContext)
  const [mailExist, setMailExist] = useState(false)
  const {coupon} = useContext(CouponContext)
  const {promoId}=useContext(PromoId)
  const {couponId}=useContext(CouponIdContext)
  const [open, setOpen] = useState(false);
  const [ setOpenn] = useState(false);
  const [ setOpennn] = useState(false);
  const {cardError, setCardError} = useContext(CardErrorContext)
  const [forgoten, setForgoten] = useState(false);
  const [error] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [currency, setCurrency] = React.useState('EUR');
  const [disabled, setDisabled] = React.useState(false)
  const [aboEtat, setAboEtat] = React.useState("")
  const [aboCreate, setAboCreate] = React.useState(false)
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: ""
  });
  let navigate = useNavigate();
  // let [forgotten, setForgotten] = useState(false)
  require('dotenv').config()
  // const SERVER_URL = process.env.REACT_APP_API_URL
  // const handler = () => {
  //   //Redirect to another route
  //   window.location.href = 'http://localhost:3000/free/pages/login/login3/success'
  // }
  // const handleClose = () => {
  //   setOpennn(false);
  // };
  useEffect( () => {
    if(aboEtat === "erreur" || open === true){
      setTimeout( () =>
    {
     setDisabled(false)
    }, 7000)
    setDisabled(true)
      }
    
  }, [aboEtat, open])

  // const handleSubmitPay = async (e) => {

  //   e.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   const res = await apiAxios.post(`pay`, {email: firebaseEmail}, authorization);

  //   const clientSecret = res.data['client_secret'];
  

  //   const result = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: {
  //       card: elements.getElement(CardElement),
  //       billing_details: {
  //         email: email,
  //       },
  //     },
  //   });

  //   if (result.error) {
  //     // Show error to your customer (e.g., insufficient funds)
  //     setOpen(true)
  //   } else {
  //     // The payment has been processed!
  //     if (result.paymentIntent.status === 'succeeded') {
  //     }
  //   }
  // };
  const options = {
    clientSecret : 'pi_3KPU0zALZg5pHqvR265ZHGnc_secret_pS9bzcBlk1ZEpSojoTfXzwc5e',
    appearence: {
      theme: 'night'
    }
}
  let stripeError;
useEffect(() => {
  
setCardError(cardError)
setTimeout(() => {
  setCardError("")
}, 7000);

}, [cardError])

useEffect(() => {
  setTimeout(() => {
    setOpen(false)
  }, 6000);
  }, [open])
  
  const handleSubmitSub = async (e) => {
    setOpen(false)
    setDisabled(true)
    // const handler = () => {
    //   //Redirect to another route
    console.log(coupon, "coupon")
    setAboEtat("en cours")
      
    // }
    setForgoten(true) 
    setTimeout( () =>
        {
         setForgoten(false);
        //  if(aboEtat === "erreur" || open === true){
        //  setDisabled(false)
        //  }
        }, 7000)
   

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    let result;
try {
  // console.log("on est la")
  result = await stripe.createPaymentMethod({
    type:'card',
    card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement),
    billing_details:{
      email: firebaseEmail
    }
  })
  // console.log(result, "result")
}catch(e)
{ 
  // console.log(e)
}    
    if(result.error){
      setAboEtat("erreur")

      // set message d'erreur en fonction du message result.error.message
      // Si erreur la montrer a l'utilisateur 
      // console.log("error 1")
      // console.log(result.error, "error found")
      stripeError = result.error
      setOpen(true)
      setCardError(result.error.message)
      // console.log(result.error, "error")
    }else {
      // console.log("else error 2")
      // au lieu de faire un else, faire une condition si le message d'erreur existe si non envoyer le payement
      // console.log('heyyy')
      if(!stripeError){
        // console.log("pas d'erreur")
        const paymentIntent = result.paymentMethod.billing_details.email
      try {
        // console.log("get Users")
        apiAxios.post(`get/users`, {}, authorization).then((res) => {
          
           for(let customer in res.data.data){
             if(res.data.data[customer].email === paymentIntent){
                 setMailExist(true);
                //  console.log('true')
                //  return true
               }
              //  else console.log("false")
           }
          //  console.log(res.data.data)
       })
      } catch (error) {
        // console.log(error)
        // console.log("error coucou")
        // console.log("error get users")
        setForgoten(true)
      }
      // recuprérer un customer par son cust_id 
      // si il existe pas, on crée un utilisateur
      // si il existe on ne crée pas un utilisateur 
      if (!mailExist || mailExist){
        // console.log("mail exist pas")
        try{
          console.log(firebaseEmail, "FirebaseEmail")
          apiAxios.post(`sub`, {'payment_method': result.paymentMethod.id, 'email': firebaseEmail, 'coupon' : coupon, 'promo_id':promoId, "coupon_id": couponId}, authorization).then((res)=>{
            // console.log("coucou")
            // console.log(res , "blibli")
            if(res.data){
              // console.log("truuuuue")
              if(res.data.raw){
              // console.log("truuuuue")
                if(res.data.decline_code){
              // console.log("truuuuue")
               
                  setCardError("Vous n'avez pas assez de fonds sur votre carte")
                }
              }
            }
           
            // if(res.data.status === "incomplete"){
               
            //   // setCardError("")
            // }
            setClient_secret(res.data)
            // setStatus(res.data)
            setCurrentUser(res.data.subscriptionId)
            setAboEtat("en cours")
                if(res.data.status === 'incomplete'){
                  // le problème vient d'ici
            // console.log("coucou 1")

                  try{
                    // console.log("confirm payment icomplete")
                    stripe.confirmCardPayment(res.data.clientSecret).then(function(result){
                      if(result.error){
                        setAboEtat("erreur")
                        setOpen(true)
                      }else{
                        setAboCreate(true)
                        // setTimeout( () =>
                        // {
                        // setForgoten(false);
                        // //  if(aboEtat === "erreur" || open === true){
                        // //  setDisabled(false)
                        // //  }
                        // }, 7000)
                        setAboEtat("en cours")
                        localStorage.setItem(
                          'currentState' , "3"
                        )
                        navigate('../success')
                      }
          })
                  }catch(error) {
  
                    setOpen(true)
            // console.log(error, "error")
          }
        }else { 
          setOpen(true)
    
          try{
            // console.log("try /pay")
            // console.log(email, "email")
            apiAxios.post(`pay`, {email: firebaseEmail}, authorization).then((res)=> {
              if(res.data === false) {
                // console.log(res, "REs")
                // setCardError(res.error)
             
                
              }else{
                try{
            // console.log("try /subs")
          
            
              //     apiAxios.post(`subs`, {'customer': customerId}, authorization).then((res)=>{
              //       localStorage.setItem(
              //         'currentState' , "3"
              //       )
              //   navigate('../success')
              // })
                }catch (error) {
       
  
                  // console.log(error)
                }
           
                setOpen(true)
              }
            })
        }catch(error){
          // console.log("ici 7")
        } 
        }
        })
        }catch(e){
          // console.log("error")
          // console.log(e)
        }
       
      }
      }
      
      }
  }
  // const CARD_OPTIONS = {
  //   iconStyle: "solid",
  //   style: {
  //     base: {
  //       iconColor: "#c4f0ff",
  //       color: "#fff",
  //       fontWeight: 500,
  //       fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  //       fontSize: "16px",
  //       fontSmoothing: "antialiased",
  //       ":-webkit-autofill": {
  //         color: "#fce883"
  //       },
  //       "::placeholder": {
  //         color: "#87bbfd"
  //       }
  //     },
  //     invalid: {
  //       iconColor: "#ffc7ee",
  //       color: "#ffc7ee"
  //     }
  //   }
  // };
  // const currencies = [
  //   {
      
  //   }
  // ];

  console.log(aboEtat + "Etat abonnement")


  // const onImageChange = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     this.setState({
  //       image: URL.createObjectURL(event.target.files[0])
  //     });
  //   }
  //  }
    
  

  return (
    <>
      <form onSubmit={()=>setOpenn(true)} style={{width: "130%", padding: 20, marginLeft: "-15%"}}>

      <fieldset className="FormGroup" style={{width:"100%", opacity: 0.77, borderRadius: "20px"}}>
        <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
        <label htmlFor="card" 
        style={{color:'#412E68 ', fontFamily: "sans-serif"}} 
        
        >Carte Bancaire</label>
        
        </div>
        <div style={{display: 'flex', justifyContent:'space-around', alignItems:"center", marginTop:"4%",marginBottom:"8%"}}>
        <img src={paiement} alt="CB" style={{  width: "15%", marginLeft: "10%"}}/>
        <img src={mastercard} alt="mastercard" style={{  width: "16%"}}/>
        <img src={visa} alt="visa" style={{  width: "19%"}}/>
        <div>
        </div>
        </div>
       
      <div style={{padding: "4%", border: '1px solid', borderRadius: "20px", marginTop: "3%"}}>
      {/* <label style={{fontSize:'55%'}}>Numéro de carte</label> */}
      <CardNumberElement style={{padding: "2%"}} id="card" options={options}/>
      </div>
     
      <div style={{display:'flex',justifyContent:"space-around"}}>

      <div style={{width:"60%",padding: "4%",border: '1px solid', borderRadius: "20px", marginTop:'3%', display: "flex", }}>
        <div style={{width: "50%"}}>
        {/* <label style={{fontSize:'55%'}}>Date d'expiration</label> */}
        <CardExpiryElement  id="card" options={options}/>
        
        </div>
        
      </div>

      <div style={{padding: "4%",width: "40%", border: '1px solid', borderRadius: "20px", marginTop:'3%'}}>
        {/* <label style={{fontSize:'55%'}}>CVC</label> */}
        <CardCvcElement id="card" options={options}/>

        </div>
        </div>
 
      </fieldset>
      <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems:'center'}}>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <Button
         disabled={disabled}
           color="primary"  variant="contained" style={{width: "200%", textTransform: 'initial'}} onClick={()=> {handleSubmitSub()
        }} >Je paye par CB</Button>
      </div>
      {forgoten && <LinearProgress color="secondary" />}
      {aboCreate && <LinearProgress color="secondary" />}

      </form>
      {/* <Dialog
  open={opennn}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">
    {"Votre abonnement"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
        Avez-vous renseigné les bonnes informations de votre carte bleue ? 
        Si vous n'êtes pas sûr, merci de bien vouloir vérifier avant de valider.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Non</Button>
    <Button onClick={()=> {handleSubmitPay()}} autoFocus>
      Oui
    </Button>
  </DialogActions>
</Dialog> */}
<Collapse in={open}>
                    <Alert
         severity='error'
         variant="filled"
        >
          <AlertTitle>Impossible</AlertTitle>
          {cardError ? 
        <div>
          {cardError}
        </div>
        :
        <div>
        Echec de paiement, veuillez réessayer avec un autre moyen de paiement

        </div>
        }
          
        </Alert>
        </Collapse>
     
      {/* <Collapse in={open}>
                    <Alert
         severity='error'
         variant="filled"
        >
          <AlertTitle>Impossible</AlertTitle>
          Pas assez de fonds sur votre carte bleue !
        </Alert>
        </Collapse> */}
        {/* <Collapse in={openn}> */}
                    {/* <Alert 
        //  severity='error'
        //  variant="filled"
        // >*/}
          {/* <AlertTitle>Impossible</AlertTitle> */}
        {/* {cardError} */}
          {/* Une erreur est survenue lors du paiement ! Vérifiez les fonds et la date d'expiration de votre carte bleue. */}
        {/* </Alert> */}
        {/* </Collapse> */}
        <div>

     

</div>
    </>
  );
    
  
};


export default CardForm;
