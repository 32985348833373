import base64 from 'react-native-base64'


const username = 'j8Bslsjdd7lmkjdp7ioh'
const password = '@@s87hfdsfjf_c68xa63-SVSJVzzHH22SSllzsqqsdsd'

const authorizationBasic = base64.encode(username + ':' + password);

export const authorization = {
    "headers": {
        "Authorization": "Basic " + authorizationBasic,
        // "Access-Control-Allow-Origin" : "https://web.application-bienetre.com"s
    }
};